<template>
    <form @submit.prevent="proceed" @keydown="schoolForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">Name</label>
                    <input class="form-control" :class="{ 'is-invalid': schoolForm.errors.has('name') }" type="text" value="" v-model="schoolForm.name" name="name" placeholder="Name">
                    <show-error :form-name="schoolForm" prop-name="name"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">Longitude</label>
                    <input class="form-control" :class="{ 'is-invalid': schoolForm.errors.has('longitude') }" type="text" value="" v-model="schoolForm.longitude" name="longitude" placeholder="Longitude">
                    <show-error :form-name="schoolForm" prop-name="longitude"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">Latitude</label>
                    <input class="form-control" :class="{ 'is-invalid': schoolForm.errors.has('latitude') }" type="text" value="" v-model="schoolForm.latitude" name="latitude" placeholder="Latitude">
                    <show-error :form-name="schoolForm" prop-name="latitude"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">Altitude</label>
                    <input class="form-control" :class="{ 'is-invalid': schoolForm.errors.has('altitude') }" type="text" value="" v-model="schoolForm.altitude" name="altitude" placeholder="Altitude">
                    <show-error :form-name="schoolForm" prop-name="altitude"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">Accuracy</label>
                    <input class="form-control" :class="{ 'is-invalid': schoolForm.errors.has('accuracy') }" type="text" value="" v-model="schoolForm.accuracy" name="accuracy" placeholder="Accuracy">
                    <show-error :form-name="schoolForm" prop-name="accuracy"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">LGA</label>
                    <select class="form-control" :class="{ 'is-invalid': schoolForm.errors.has('lga_id') }" name="lga_id" v-model="schoolForm.lga_id">
                        <option value="">LGA</option>
                        <option v-for="lga in lgas" :value="lga.id">{{lga.name}}</option>
                    </select>
                    <show-error :form-name="schoolForm" prop-name="lga_id"></show-error>
                </div>
            </div>

        </div>
        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
            <span v-if="id">{{trans('general.update')}}</span>
            <span v-else>{{trans('general.save')}}</span>
        </button>
        <router-link to="/schools" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}</router-link>
        
    </form>
</template>


<script>
    import datepicker from 'vuejs-datepicker'
    export default {
        components: {datepicker},
        data() {
            return {
                schoolForm: new Form({
                    name: '',
                    longitude: '',
                    latitude: '',
                    altitude: '',
                    accuracy: '',
                    lga_id: '',
                }),
                lgas: [],
            };
        },
        props: ['id'],
        mounted() {
            this.fetchPreRequisites();
            if(this.id)
                this.getSchool();
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.lgas = response.lgas;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updateSchool();
                else
                    this.storeSchool();
            },
            storeSchool(){
                this.schoolForm.post('/api/schools')
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/schools');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getSchool(){
                axios.get('/api/schools/'+this.id)
                    .then(response => response.data.school)
                    .then(response => {
                        this.schoolForm.name = response.name;
                        this.schoolForm.longitude = response.longitude;
                        this.schoolForm.latitude = response.latitude;
                        this.schoolForm.altitude = response.altitude;
                        this.schoolForm.accuracy = response.accuracy;
                        this.schoolForm.lga_id = response.lga_id;
                    
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/schools');
                    });
            },
            updateSchool(){
                this.schoolForm.patch('/api/schools/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/schools');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
