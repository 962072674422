<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('ip_filter.edit_ip_filter')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">{{trans('ip_filter.ip_filter')}}</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('ip_filter.edit_ip_filter')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/configuration/ip-filter')"><i class="feather icon-filter"></i> {{trans('ip_filter.ip_filter')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('ip_filter.edit_ip_filter')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <ip-filter-form :id="id"></ip-filter-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ipFilterForm from './form';

    export default {
        components : { ipFilterForm },
        data() {
            return {
                id:this.$route.params.id
            }
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('ip_filter')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
        }
    }
</script>
