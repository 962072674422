var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.eventForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("calendar.title"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eventForm.title,
                    expression: "eventForm.title",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.eventForm.errors.has("title") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "title",
                  placeholder: _vm.trans("calendar.title"),
                },
                domProps: { value: _vm.eventForm.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.eventForm, "title", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.eventForm, "prop-name": "title" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("calendar.description"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eventForm.description,
                    expression: "eventForm.description",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.eventForm.errors.has("description"),
                },
                attrs: {
                  rows: "1",
                  name: "description",
                  placeholder: _vm.trans("calendar.description"),
                },
                domProps: { value: _vm.eventForm.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.eventForm, "description", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.eventForm,
                  "prop-name": "description",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("calendar.start"))),
              ]),
              _vm._v(" "),
              _c("datepicker", {
                class: {
                  "form-control is-invalid": _vm.eventForm.errors.has("start"),
                },
                attrs: {
                  bootstrapStyling: true,
                  placeholder: _vm.trans("calendar.start"),
                },
                on: {
                  selected: function ($event) {
                    return _vm.eventForm.errors.clear("start")
                  },
                },
                model: {
                  value: _vm.eventForm.start,
                  callback: function ($$v) {
                    _vm.$set(_vm.eventForm, "start", $$v)
                  },
                  expression: "eventForm.start",
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.eventForm, "prop-name": "start" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("calendar.end"))),
              ]),
              _vm._v(" "),
              _c("datepicker", {
                class: {
                  "form-control is-invalid": _vm.eventForm.errors.has("end"),
                },
                attrs: {
                  bootstrapStyling: true,
                  placeholder: _vm.trans("calendar.end"),
                },
                on: {
                  selected: function ($event) {
                    return _vm.eventForm.errors.clear("end")
                  },
                },
                model: {
                  value: _vm.eventForm.end,
                  callback: function ($$v) {
                    _vm.$set(_vm.eventForm, "end", $$v)
                  },
                  expression: "eventForm.end",
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.eventForm, "prop-name": "end" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
          attrs: { type: "submit" },
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.id,
              expression: "id",
            },
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { to: "/todo" },
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }