<template>

<div class="main-menu menu-fixed menu-dark menu-accordion menu-shadow semi-dark" data-scroll-to-active="true">
    <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
            <li class="nav-item mr-auto"><a class="navbar-brand" href="dashboard-ecommerce">
                    <div style="background-position: -120px -10px;">
                        <img :src="getMainLogo" alt="" class="" height="24" width="35" />
                    </div>

                    <h2 class="brand-text mb-0" style="color: rgb(40, 199, 111);">{{ companyName }}</h2>
                </a></li>
            <li class="nav-item nav-toggle">
                <a class="nav-link modern-nav-toggle pr-0 shepherd-modal-target" data-toggle="collapse"><i class="icon-x d-block d-xl-none font-medium-4 toggle-icon feather icon-disc" style="color: rgb(40, 199, 111);"></i><i class="toggle-icon icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon feather" data-ticon="icon-disc" tabindex="0" style="color: rgb(40, 199, 111);"></i></a>
            </li>
        </ul>
    </div>
    <div class="shadow-bottom"></div>
    <div class="main-menu-content">
        <main-menu></main-menu>
    </div>
</div>
<!-- END: Main Menu-->
</template>

<script>
    import mainMenu from './menu'

    export default {
        components: {mainMenu},
        mounted() {
        },
        methods : {
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login');
                })
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        },
        computed: {
            getMainLogo(){
                if(helper.getConfig('main_logo'))
                    return '/'+helper.getConfig('main_logo');
                else
                    return '/images/logo/logo-success.png';
            },
            companyName() {
                return this.getConfig('company_name').replace(/ .*/, '');
            }
        }
    }
</script>
