<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('role.role')}}
                            <span class="card-subtitle" v-if="roles">{{trans('general.total_result_found',{'count' : roles.total})}}</span>
                            <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('role.role')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="showCreatePanel = !showCreatePanel"><i class="feather icon-plus"></i> {{trans('role.add_new_role')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <transition name="fade">
                            <div class="card"  v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('role.add_new_role')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <role-form @completed="getRoles" @cancel="showCreatePanel = !showCreatePanel"></role-form>
                                </div>
                            </div>
                        </div>
                        </transition>
                    </div>
                </div>
            </section>

            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                        
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="roles.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('role.name')}}</th>
                                            <th>{{trans('general.created_at')}}</th>
                                            <th class="table-option">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="role in roles.data">
                                            <td v-text="toWord(role.name)"></td>
                                            <td>{{role.created_at | momentDateTime}}</td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-danger btn-sm" :key="role.id" v-confirm="{ok: confirmDelete(role)}" v-tooltip="trans('role.delete_role')"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!roles.total" module="role" title="module_info_title" description="module_info_description" icon="lock"></module-info>
                            <pagination-record :page-length.sync="filterRoleForm.page_length" :records="roles" @updateRecords="getRoles" @change.native="getRoles"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            
        </div>

        
    </div>
</template>

<script>
    import roleForm from './form'

    export default {
        components : { roleForm },
        data() {
            return {
                roles: {
                    total: 0,
                    data: []
                },
                filterRoleForm: {
                    page_length: helper.getConfig('page_length')
                },
                showCreatePanel: false
            };
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            this.getRoles();
        },
        methods: {
            getRoles(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterRoleForm);
                axios.get('/api/role?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.roles = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            confirmDelete(role){
                return dialog => this.deleteRole(role);
            },
            deleteRole(role){
                axios.delete('/api/role/'+role.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getRoles();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            toWord(word){
                return helper.toWord(word);
            }
        },
        filters: {
          momentDatetime(date) {
            return helper.formatDateTime(date);
          }
        }
    }
</script>
