<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Payment Schedules</h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Settings</a>
                                </li>
                                <li class="breadcrumb-item active">Payment Schedules</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i> <span class="d-none d-sm-inline">Add New Payment Schedule</span></button>
                    
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> <span class="d-none d-sm-inline">{{trans('general.filter')}}</span></button>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="card border-bottom" v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">Add New Payment Schedule</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <paymentSchedule-form @completed="getPaymentSchedules" @cancel="showCreatePanel = !showCreatePanel"></paymentSchedule-form>
                            </div>
                            </div>
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">{{trans('general.keyword')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterPaymentScheduleForm.keyword">
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="paymentSchedules.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterPaymentScheduleForm.sort_by" :order="filterPaymentScheduleForm.order" @updateSortBy="value => {filterPaymentScheduleForm.sort_by = value}"  @updateOrder="value => {filterPaymentScheduleForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="paymentSchedules.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Cohort</th>
                                            <th>Cycle</th>
                                            <th>Tranch</th>
                                            <th>Amount</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="paymentSchedule in paymentSchedules.data">
                                            <td v-text="paymentSchedule.cohort.name"></td>
                                            <td v-text="paymentSchedule.cycle.name"></td>
                                            <td v-text="paymentSchedule.trench.name"></td>
                                            <td>{{ paymentSchedule.trench.amount | formatMoney }}</td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-info btn-sm" v-tooltip="'Edit PaymentSchedule'" @click.prevent="editPaymentSchedule(paymentSchedule)"><i class="feather icon-edit"></i></button>
                                                    <button class="btn btn-danger btn-sm" :key="paymentSchedule.id" v-confirm="{ok: confirmDelete(paymentSchedule)}" v-tooltip="'Delete PaymentSchedule'"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!paymentSchedules.total"  module="general" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" v-if="!showCreatePanel" @click="showCreatePanel = !showCreatePanel"><i class="feather icon-plus"></i> {{trans('general.add_new')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterPaymentScheduleForm.page_length" :records="paymentSchedules" @updateRecords="getPaymentSchedules"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import paymentScheduleForm from './form'
    import sortBy from '../../../components/sort-by'

    export default {
        components : { paymentScheduleForm,sortBy },
        data() {
            return {
                paymentSchedules: {
                    total: 0,
                    data: []
                },
                filterPaymentScheduleForm: {
                    keyword: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
                orderByOptions: [
                    {
                        value: 'school_id',
                        translation: 'School'
                    },
                    {
                        value: 'payment_type_id',
                        translation: 'Payment Type'
                    },
                    {
                        value: 'amount',
                        translation: 'Amount'
                    }
                ],
                showCreatePanel: false,
                showFilterPanel: false
            };
        },
        mounted(){
            if(!helper.hasPermission('create-payment-schedule')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            this.getPaymentSchedules();
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getPaymentSchedules(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterPaymentScheduleForm);
                axios.get('/api/setting/payment-schedule?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.paymentSchedules = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            editPaymentSchedule(paymentSchedule){
                this.$router.push('/setting/payment-schedule/'+paymentSchedule.id+'/edit');
            },
            confirmDelete(paymentSchedule){
                return dialog => this.deletePaymentSchedule(paymentSchedule);
            },
            deletePaymentSchedule(paymentSchedule){
                axios.delete('/api/setting/payment-schedule/'+paymentSchedule.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getPaymentSchedules();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            }
        },
        watch: {
            filterPaymentScheduleForm: {
                handler(val){
                    setTimeout(() => {
                        this.getPaymentSchedules();
                    }, 500)
                },
                deep: true
            }
        },

        filters: {
          formatMoney(value) {
            return helper.formatMoney(value);
          }
        }
    }
</script>
