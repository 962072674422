var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar" }, [
      _c("div", { staticClass: "sidebar-content email-app-sidebar d-flex" }, [
        _c("div", { staticClass: "email-app-menu" }, [
          _c(
            "div",
            {
              staticClass:
                "form-group form-group-compose text-center compose-btn",
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary btn-block my-2",
                  attrs: { to: "/message/compose" },
                },
                [
                  _c("i", { staticClass: "feather icon-edit" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.trans("message.compose")) +
                      "\n            \n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sidebar-menu-list" }, [
            _c(
              "div",
              { staticClass: "list-group list-group-messages font-medium-1" },
              [
                _c(
                  "router-link",
                  {
                    class: [
                      _vm.menu === "inbox" ? "active" : "",
                      "list-group-item list-group-item-action border-0 pt-0",
                    ],
                    attrs: { to: "/message/inbox" },
                  },
                  [
                    _c("i", {
                      staticClass: "font-medium-5 feather icon-mail mr-50",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("message.inbox")) +
                        "\n                "
                    ),
                    _vm.statistics.inbox
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-primary badge-pill float-right",
                          },
                          [_vm._v(_vm._s(_vm.statistics.inbox))]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    class: [
                      _vm.menu === "sent" ? "active" : "",
                      "list-group-item list-group-item-action border-0",
                    ],
                    attrs: { to: "/message/sent" },
                  },
                  [
                    _c("i", {
                      staticClass: "font-medium-5 fa fa-paper-plane-o mr-50",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("message.sent_box")) +
                        "\n                "
                    ),
                    _vm.statistics.sent
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-primary badge-pill float-right",
                          },
                          [_vm._v(_vm._s(_vm.statistics.sent))]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    class: [
                      _vm.menu === "draft" ? "active" : "",
                      "list-group-item list-group-item-action border-0",
                    ],
                    attrs: { to: "/message/draft" },
                  },
                  [
                    _c("i", {
                      staticClass: "font-medium-5 feather icon-edit-2 mr-50",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("message.draft")) +
                        "\n                "
                    ),
                    _vm.statistics.draft
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-primary badge-pill float-right",
                          },
                          [_vm._v(_vm._s(_vm.statistics.draft))]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    class: [
                      _vm.menu === "important" ? "active" : "",
                      "list-group-item list-group-item-action border-0",
                    ],
                    attrs: { to: "/message/important" },
                  },
                  [
                    _c("i", {
                      staticClass: "font-medium-5 feather icon-star mr-50",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("message.important")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    class: [
                      _vm.menu === "trash" ? "active" : "",
                      "list-group-item list-group-item-action border-0",
                    ],
                    attrs: { to: "/message/trash" },
                  },
                  [
                    _c("i", {
                      staticClass: "font-medium-5 feather icon-trash-2 mr-50",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.trans("message.trash")) +
                        "\n                "
                    ),
                    _vm.statistics.trash
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-primary badge-pill float-right",
                          },
                          [_vm._v(_vm._s(_vm.statistics.trash))]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "list-group-item list-group-item-action border-0",
        attrs: { href: "#" },
      },
      [
        _c("i", { staticClass: "font-medium-5 fa fa-paper-plane-o mr-50" }),
        _vm._v(" Sent"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }