var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("role.role")) + "\n                        "
              ),
              _vm.roles
                ? _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("general.total_result_found", {
                          count: _vm.roles.total,
                        })
                      )
                    ),
                  ])
                : _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found"))),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.trans("role.role"))),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function ($event) {
                    _vm.showCreatePanel = !_vm.showCreatePanel
                  },
                },
              },
              [
                _c("i", { staticClass: "feather icon-plus" }),
                _vm._v(" " + _vm._s(_vm.trans("role.add_new_role"))),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showCreatePanel
                  ? _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("role.add_new_role"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("role-form", {
                              on: {
                                completed: _vm.getRoles,
                                cancel: function ($event) {
                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.roles.total
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("role.name"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.trans("general.created_at"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v("Action"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.roles.data, function (role) {
                                return _c("tr", [
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.toWord(role.name)
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("momentDateTime")(
                                          role.created_at
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _c("div", { staticClass: "btn-group" }, [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "confirm",
                                              rawName: "v-confirm",
                                              value: {
                                                ok: _vm.confirmDelete(role),
                                              },
                                              expression:
                                                "{ok: confirmDelete(role)}",
                                            },
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                _vm.trans("role.delete_role"),
                                              expression:
                                                "trans('role.delete_role')",
                                            },
                                          ],
                                          key: role.id,
                                          staticClass: "btn btn-danger btn-sm",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "feather icon-trash-2",
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.roles.total
                      ? _c("module-info", {
                          attrs: {
                            module: "role",
                            title: "module_info_title",
                            description: "module_info_description",
                            icon: "lock",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterRoleForm.page_length,
                        records: _vm.roles,
                      },
                      on: {
                        "update:pageLength": function ($event) {
                          return _vm.$set(
                            _vm.filterRoleForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function ($event) {
                          return _vm.$set(
                            _vm.filterRoleForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getRoles,
                      },
                      nativeOn: {
                        change: function ($event) {
                          return _vm.getRoles.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }