var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.attendancesForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.cohort"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attendancesForm.cohort_id,
                      expression: "attendancesForm.cohort_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.attendancesForm.errors.has("cohort_id"),
                  },
                  attrs: { name: "cohort_id", disabled: _vm.id },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.attendancesForm,
                        "cohort_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.cohort"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cohorts, function (cohort) {
                    return _c("option", { domProps: { value: cohort.id } }, [
                      _vm._v(_vm._s(cohort.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.attendancesForm,
                  "prop-name": "cohort_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("attendance.cycle"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attendancesForm.cycle_id,
                      expression: "attendancesForm.cycle_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.attendancesForm.errors.has("cycle_id"),
                  },
                  attrs: { name: "cycle_id" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.attendancesForm,
                        "cycle_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("attendance.cycle"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cycles, function (cycle) {
                    return _c("option", { domProps: { value: cycle.id } }, [
                      _vm._v(_vm._s(cycle.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.attendancesForm,
                  "prop-name": "cycle_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("attendance.term"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attendancesForm.term_id,
                      expression: "attendancesForm.term_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.attendancesForm.errors.has("term_id"),
                  },
                  attrs: { name: "term_id" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.attendancesForm,
                        "term_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("attendance.term"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.terms, function (term) {
                    return _c("option", { domProps: { value: term.id } }, [
                      _vm._v(_vm._s(term.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.attendancesForm,
                  "prop-name": "term_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("school.school"))),
              ]),
              _vm._v(" "),
              _c("v-select", {
                class: {
                  "form-control is-invalid":
                    _vm.attendancesForm.errors.has("school_id"),
                },
                attrs: {
                  options: _vm.schools,
                  reduce: (name) => name.id,
                  label: "name",
                  name: "school_id",
                },
                on: { input: _vm.getBeneficiaries },
                model: {
                  value: _vm.attendancesForm.school_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.attendancesForm, "school_id", $$v)
                  },
                  expression: "attendancesForm.school_id",
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.attendancesForm,
                  "prop-name": "school_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("attendance.week"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attendancesForm.week,
                      expression: "attendancesForm.week",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.attendancesForm.errors.has("week"),
                  },
                  attrs: { name: "week" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.attendancesForm,
                        "week",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("attendance.week"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.weeks, function (week) {
                    return _c("option", { domProps: { value: week.name } }, [
                      _vm._v(_vm._s(week.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.attendancesForm,
                  "prop-name": "week",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-12 col-12" }, [
          _c("div", { staticClass: "table-responsive m-b-20" }, [
            _c("table", { staticClass: "table table-hover" }, [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("th", [_vm._v("Beneficiary")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Admission No.")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Class")]),
                    _vm._v(" "),
                    _vm._l(_vm.days, function (day) {
                      return _c("th", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(day.name)),
                      ])
                    }),
                    _vm._v(" "),
                    _c("th", [_vm._v("Reason")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Remark")]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.beneficiaries, function (beneficiary) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(beneficiary.name))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(beneficiary.unique_id))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(beneficiary.clas.name))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("switches", {
                          attrs: { theme: "bootstrap", color: "success" },
                          model: {
                            value:
                              _vm.attendancesForm.monday[beneficiary.id][
                                "attendance"
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendancesForm.monday[beneficiary.id],
                                "attendance",
                                $$v
                              )
                            },
                            expression:
                              "attendancesForm.monday[beneficiary.id]['attendance']",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("switches", {
                          attrs: { theme: "bootstrap", color: "success" },
                          model: {
                            value:
                              _vm.attendancesForm.tuesday[beneficiary.id][
                                "attendance"
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendancesForm.tuesday[beneficiary.id],
                                "attendance",
                                $$v
                              )
                            },
                            expression:
                              "attendancesForm.tuesday[beneficiary.id]['attendance']",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("switches", {
                          attrs: { theme: "bootstrap", color: "success" },
                          model: {
                            value:
                              _vm.attendancesForm.wednesday[beneficiary.id][
                                "attendance"
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendancesForm.wednesday[beneficiary.id],
                                "attendance",
                                $$v
                              )
                            },
                            expression:
                              "attendancesForm.wednesday[beneficiary.id]['attendance']",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("switches", {
                          attrs: { theme: "bootstrap", color: "success" },
                          model: {
                            value:
                              _vm.attendancesForm.thursday[beneficiary.id][
                                "attendance"
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendancesForm.thursday[beneficiary.id],
                                "attendance",
                                $$v
                              )
                            },
                            expression:
                              "attendancesForm.thursday[beneficiary.id]['attendance']",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("switches", {
                          attrs: { theme: "bootstrap", color: "success" },
                          model: {
                            value:
                              _vm.attendancesForm.friday[beneficiary.id][
                                "attendance"
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendancesForm.friday[beneficiary.id],
                                "attendance",
                                $$v
                              )
                            },
                            expression:
                              "attendancesForm.friday[beneficiary.id]['attendance']",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.attendancesForm.reason[beneficiary.id],
                            expression:
                              "attendancesForm.reason[beneficiary.id]",
                          },
                        ],
                        key: beneficiary.id,
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "",
                          name: "reason",
                          placeholder: "Reason",
                        },
                        domProps: {
                          value: _vm.attendancesForm.reason[beneficiary.id],
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.attendancesForm.reason,
                              beneficiary.id,
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.attendancesForm.remark[beneficiary.id],
                            expression:
                              "attendancesForm.remark[beneficiary.id]",
                          },
                        ],
                        key: beneficiary.id,
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "",
                          name: "remark",
                          placeholder: "Remark",
                        },
                        domProps: {
                          value: _vm.attendancesForm.remark[beneficiary.id],
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.attendancesForm.remark,
                              beneficiary.id,
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8 offset-md-4" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
              attrs: { type: "submit" },
            },
            [
              _vm.id
                ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }