var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "invalid-feedback", attrs: { role: "alert" } },
    [
      _vm.formName.errors.has(_vm.propName)
        ? _c("strong", {
            domProps: {
              textContent: _vm._s(_vm.formName.errors.get(_vm.propName)),
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }