<template>
    <section class="row flexbox-container">
      <div class="col-xl-7 col-md-8 col-12 d-flex justify-content-center">
        <div class="card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
          <div class="card-content">
            <div class="card-body text-center">
              <img src="images/pages/not-authorized.png" class="img-fluid align-self-center" alt="branding logo">
              <h1 class="font-large-2 my-2">IP Restricted</h1>
              <p class="p-2">
                Unfortunately, This IP is not allowed to access this page!
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
    export default {
        mounted() {
            if(!localStorage.getItem('ip_restricted'))
                this.$router.push('/home');
        }
    }
</script>
