<template>
	<section class="card overflow-hidden">
		<div class="card-header">
			<h4 v-if="title" class="card-title">
				<i v-if="icon" :class="['feather','icon-'+icon]"></i>
				{{ trans(module+'.'+title) }}
			</h4>
		</div>

		<div class="card-content">
			<div class="card-body">
				<div class="card-text">
					<p class="text-success" v-if="description" v-text="trans(module+'.'+description)"></p>

					<slot name="btn"></slot>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: ['module','icon','title','description']
	}
</script>