var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "navigation navigation-main",
      attrs: { id: "main-menu-navigation", "data-menu": "menu-navigation" },
    },
    [
      _c(
        "li",
        {
          class: [
            _vm.currentPage.includes("home") ? _vm.activeClass : "",
            "nav-item",
          ],
        },
        [
          _c("router-link", { attrs: { to: "/home", exact: "" } }, [
            _c("i", { staticClass: "feather icon-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "menu-title" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item has-sub",
          class: [
            _vm.currentPage.includes("beneficiaries") ? _vm.activeClass : "",
          ],
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("ul", { staticClass: "menu-content" }, [
            _vm.hasPermission("upload-selected-beneficiaries")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/beneficiaries/import", exact: "" } },
                      [
                        _c("i", { staticClass: "feather icon-list" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-title" }, [
                          _vm._v(
                            "\n\t    \t\t\t\t\tUpload Selected\n\t    \t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("view-cohort-selected")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/beneficiaries/cohort", exact: "" } },
                      [
                        _c("i", { staticClass: "feather icon-list" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-title" }, [
                          _vm._v(
                            "\n\t    \t\t\t\t\tCohort Selected\n\t    \t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("view-enrolled-list")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/beneficiaries/enrolled", exact: "" } },
                      [
                        _c("i", { staticClass: "feather icon-list" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-title" }, [
                          _vm._v(
                            "\n\t    \t\t\t\t\tEnrolled List\n\t    \t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("view-enrolled-list")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/beneficiaries/validated", exact: "" } },
                      [
                        _c("i", { staticClass: "feather icon-list" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-title" }, [
                          _vm._v(
                            "\n\t    \t\t\t\t\tValidated List\n\t    \t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("view-bank-accounts")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/beneficiaries/bank-accounts",
                          exact: "",
                        },
                      },
                      [
                        _c("i", { staticClass: "feather icon-list" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-title" }, [
                          _vm._v(
                            "\n\t    \t\t\t\t\tBank Accounts\n\t    \t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.hasPermission("view-attendance")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("attendance") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/attendances", exact: "" } }, [
                _c("i", { staticClass: "feather icon-clock" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t    \t\t\tAttendance\n\t    \t\t"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-payments")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("list-payments")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("payments")
                          ? _vm.activeClass
                          : "",
                      ],
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/payments", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t    \t\t\t\t\tPayments\n\t    \t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("create-payment-schedule")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("payment-schedule")
                          ? _vm.activeClass
                          : "",
                      ],
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/setting/payment-schedule", exact: "" },
                        },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t            \t\t\tPayment Schedules\n\t            \t\t"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-schools")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("schools") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/schools", exact: "" } }, [
                _c("i", { staticClass: "feather icon-database" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t    \t\t\tSchools\n\t    \t\t"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-user") && _vm.getConfig("show_user_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("user") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/user", exact: "" } }, [
                _c("i", { staticClass: "feather icon-user-plus" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t\t\t\t\tUsers\n\t\t\t\t"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-report")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("reports") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/reports", exact: "" } }, [
                _c("i", { staticClass: "feather icon-trending-up" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t    \t\t\tReports\n\t    \t\t"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-settings")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("cohort") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/cohort", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tCohort\n\t    \t\t\t\t"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/cycle")
                      ? _vm.activeClass
                      : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/cycle", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tCycle\n\t            \t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/trench")
                      ? _vm.activeClass
                      : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/trench", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tTrench\n\t            \t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/session")
                      ? _vm.activeClass
                      : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/session", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tSession\n\t            \t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/term")
                      ? _vm.activeClass
                      : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/term", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tTerm\n\t            \t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("lga") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/lga", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tLGA\n\t    \t\t\t\t"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-todo") && _vm.getConfig("show_todo_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("todo") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/todo", exact: "" } }, [
                _c("i", { staticClass: "feather icon-check-circle" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("todo.todo")) +
                      "\n\t    \t\t"
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-message") && _vm.getConfig("show_message_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("message") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/message", exact: "" } }, [
                _c("i", { staticClass: "feather icon-mail" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("message.message")) +
                      "\n\t    \t\t"
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-sms") && _vm.getConfig("show_sms_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("configuration")
                  ? _vm.activeClass
                  : "",
                "nav-item",
              ],
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/sms", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-message-circle" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("general.sms")) +
                        "\n\t    \t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_email_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("access-configuration") &&
              _vm.getConfig("show_email_template_menu")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("email-template")
                          ? _vm.activeClass
                          : "",
                      ],
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/email-template", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-mail" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t\t\t    \t\t\t" +
                                _vm._s(_vm.trans("template.email_template")) +
                                "\n\t\t\t    \t\t"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("access-configuration") &&
              _vm.getConfig("show_email_log_menu")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("email-log")
                          ? _vm.activeClass
                          : "",
                      ],
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/email-log", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-mail" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t\t\t    \t\t\t" +
                                _vm._s(_vm.trans("mail.email_log")) +
                                "\n\t\t\t    \t\t"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_settings_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("basic") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/basic", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-settings" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(
                              _vm.trans("configuration.basic_configuration")
                            ) +
                            "\n\t        \t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("logo") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/logo", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-image" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\t" +
                            _vm._s(_vm.trans("general.logo")) +
                            "\n\t            \t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("system") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/system", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-settings" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(
                              _vm.trans("configuration.system_configuration")
                            ) +
                            "\n\t    \t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("mail") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/mail", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-mail" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("mail.mail_configuration")) +
                            "\n\t    \t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.getConfig("multilingual")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("locale")
                          ? _vm.activeClass
                          : "",
                      ],
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/configuration/locale", exact: "" } },
                        [
                          _c("i", { staticClass: "fas fa-globe" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t    \t\t\t\t\t" +
                                _vm._s(_vm.trans("locale.locale")) +
                                "\n\t    \t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("menu") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/menu", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("general.menu")) +
                            "\n\t    \t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("authentication")
                      ? _vm.activeClass
                      : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/configuration/authentication", exact: "" },
                    },
                    [
                      _c("i", { staticClass: "feather icon-log-in" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(_vm.trans("auth.authentication")) +
                            "\n\t        \t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_permission_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("role") ? _vm.activeClass : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/role", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-log-in" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(_vm.trans("role.role")) +
                            "\n\t        \t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("permission")
                      ? _vm.activeClass
                      : "",
                  ],
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/permission", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-lock" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("permission.permission")) +
                            "\n\t    \t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_activity_log_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("activity-log") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/activity-log", exact: "" } }, [
                _c("i", { staticClass: "feather icon-activity" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("activity.activity_log")) +
                      "\n\t    \t\t"
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") && _vm.getConfig("ip_filter")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("ip-filter") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/ip-filter", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-filter" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("ip_filter.ip_filter")) +
                        "\n\t    \t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_schedule_job_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("scheduled-job")
                  ? _vm.activeClass
                  : "",
                "nav-item",
              ],
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/scheduled-job", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-clock" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("general.scheduled_job")) +
                        "\n\t    \t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_backup_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("backup") ? _vm.activeClass : "",
                "nav-item",
              ],
            },
            [
              _c("router-link", { attrs: { to: "/backup", exact: "" } }, [
                _c("i", { staticClass: "feather icon-box" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("backup.backup")) +
                      "\n\t    \t\t"
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-users" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Beneficiaries"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-dollar-sign" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Payments"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-settings" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Settings"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-inbox" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("\n\t   \t\t\t\tEmail\n   \t\t\t\t"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-settings" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("System Settings"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-lock" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Permissions"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }