<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('user.user')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">User</a>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/user')"><i class="feather icon-list"></i> {{trans('user.user_list')}}</button>
                </div>
            </div>
        </div>

    <div class="content-body">
        <div id="user-profile">
            <user-sidebar menu="password" :id="id"></user-sidebar>
  
  <section id="profile-info">
    <div class="row">
        <user-summary :user="user"></user-summary>
      
      <div class="col-lg-8 col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{trans('user.password')}}</h4>
            </div>
          <div class="card-content">
              <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-1">
                <form @submit.prevent="submit" @keydown="passwordForm.errors.clear($event.target.name)">
                                        <h4 class="card-title">{{trans('auth.change_password')}}</h4>
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="">{{trans('auth.new_password')}}</label>
                                                    <div v-if="getConfig('password_strength_meter')">
                                                        <password v-model="passwordForm.new_password" name="new_password" defaultClass="form-control" :placeholder="trans('auth.new_password')" :required="false"></password>
                                                    </div>
                                                    <div v-else>
                                                        <input type="password" name="new_password" class="form-control" :class="{ 'is-invalid': passwordForm.errors.has('new_password') }" :placeholder="trans('auth.new_password')" v-model="passwordForm.new_password">
                                                    </div>
                                                    <show-error :form-name="passwordForm" prop-name="new_password"></show-error>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="">{{trans('auth.new_password_confirmation')}}</label>
                                                    <input class="form-control" :class="{ 'is-invalid': passwordForm.errors.has('new_password_confirmation') }" type="password" value="" v-model="passwordForm.new_password_confirmation" name="new_password_confirmation" :placeholder="trans('auth.new_password_confirmation')">
                                                    <show-error :form-name="passwordForm" prop-name="new_password_confirmation"></show-error>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">{{trans('general.save')}}</button>
                                    </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
   
   </div>
    </div>
</template>

<script>
    import userSidebar from './user-sidebar'
    import userSummary from './summary'
    import password from 'vue-password-strength-meter'

    export default {
        components : { userSidebar,userSummary,password },
        data() {
            return {
                id:this.$route.params.id,
                passwordForm: new Form({
                    new_password: '',
                    new_password_confirmation: ''
                }),
                user: ''
            };
        },
        mounted(){
            if(this.id == helper.getAuthUser('id'))
                this.$router.push('/user');

            if(!helper.hasPermission('force-reset-user-password')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            axios.get('/api/user/'+this.id)
                .then(response => response.data)
                .then(response => {
                    this.user = response.user;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
            submit(){
                this.passwordForm.patch('/api/user/'+this.id+'/force-reset-password')
                    .then(response => {
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
