<template>
    <section class="row flexbox-container">
  <div class="col-xl-8 col-md-9 col-10 d-flex justify-content-center px-0">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-6 d-lg-block d-none text-center align-self-center">
                  <img src="/images/pages/login.jpeg" width="332px" alt="branding logo">
              </div>
              <div class="col-lg-6 col-12 p-0">
                  <div class="card rounded-0 mb-0 px-2 py-1">
                      <div class="card-header pb-1">
                          <div class="card-title">
                              <h4 class="mb-0">{{trans('auth.account_activation')}}</h4>
                          </div>
                      </div>
                      <div class="card-content">
                          <div class="card-body">
                            <div v-if="status" class="alert alert-success" role="alert">
                                <p class="mb-0" v-text="message"></p>
                              </div>

                              <div v-else class="alert alert-danger" role="alert">
                                <p class="mb-0" v-text="message"></p>
                              </div>
                             

                                  <div class="float-md-left d-block mb-1">
                                    <router-link to="/login" class="btn btn-outline-primary btn-block px-75">{{trans('auth.back_to_login?')}}</router-link>
                                  </div>

                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
</section>
</template>

<script>
    import guestFooter from '../../layouts/guest-footer.vue'

    export default {
        data() {
            return {
                token:this.$route.params.token,
                message: i18n.general.processing,
                status: true
            }
        },
        components: {
            guestFooter
        },
        mounted(){

            if(!helper.featureAvailable('registration') || !helper.featureAvailable('email_verification')){
                helper.featureNotAvailableMsg();
                return this.$router.push('/home');
            }

            axios.get('/api/auth/activate/'+this.token)
                .then(response => response.data)
                .then(response =>  {
                    this.message = response.message;
                }).catch(error => {
                    helper.showDataErrorMsg(error);
                    this.status = false;
                });
        }
    }
</script>
