<template>
	<span class="invalid-feedback" role="alert">
        <strong v-if="formName.errors.has(propName)" v-text="formName.errors.get(propName)">
            
        </strong>
    </span>
</template>

<script>
	export default {
		props:['formName','propName']
	}
</script>