var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "card overflow-hidden" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm.title
        ? _c("h4", { staticClass: "card-title" }, [
            _vm.icon
              ? _c("i", { class: ["feather", "icon-" + _vm.icon] })
              : _vm._e(),
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.trans(_vm.module + "." + _vm.title)) +
                "\n\t\t"
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "card-text" },
          [
            _vm.description
              ? _c("p", {
                  staticClass: "text-success",
                  domProps: {
                    textContent: _vm._s(
                      _vm.trans(_vm.module + "." + _vm.description)
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._t("btn"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }