<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.beneficiary')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/beneficiary">
                                        {{ trans('beneficiary.beneficiary') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('beneficiary.beneficiary_file_upload') }}
                                </li>


                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/beneficiaries')" v-if="hasPermission('list-beneficiaries')"><i class="feather icon-list"></i> {{trans('beneficiary.beneficiary_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('beneficiary.beneficiary_file_upload')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <form @submit.prevent="proceed" @keydown="beneficiaryForm.errors.clear($event.target.name)">
                                    <div class="row">
                                        
        <div class="col-md-12 mb-1">
            <a href="/files/new-beneficiaries.xlsx" download class="btn btn-primary btn-sm waves-effect waves-float waves-light pull-left mr-1"><i class="fas fa-download"></i> {{ trans('general.download_new_sample') }}</a>

            <a href="/files/existing-beneficiaries.xlsx" download class="btn btn-primary btn-sm waves-effect waves-float waves-light pull-left"><i class="fas fa-download"></i> {{ trans('general.download_existing_sample') }}</a>
            
        </div>
        <div class="col-xl-2 col-md-2 col-12">
                <div class="form-group">
                    <label for="">Type</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiaryForm.errors.has('type') }" name="type" v-model="beneficiaryForm.type">
                        <option value="1">New</option>
                        <option value="2">Existing</option>
                    </select>
                    <show-error :form-name="beneficiaryForm" prop-name="type"></show-error>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.cohort')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiaryForm.errors.has('cohort_id') }" name="cohort_id" v-model="beneficiaryForm.cohort_id">
                        <option value="">{{trans('beneficiary.cohort')}}</option>
                        <option v-for="cohort in cohorts" :value="cohort.id">{{cohort.name}}</option>
                    </select>
                    <show-error :form-name="beneficiaryForm" prop-name="cohort_id"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">{{trans('beneficiary.lga')}}</label>
                        <v-select @input="getSchools" :options="lgas" label="name" :class="{ 'form-control is-invalid': beneficiaryForm.errors.has('lga_id') }" name="lga_id" v-model="beneficiaryForm.lga_id">
                            <template #option="{ id, name, status }">
                              <span :style="status == 1 ? 'color: green; font-weight: bold' : ''">{{ name }}</span>
                            </template>
                        </v-select>
                        <show-error :form-name="beneficiaryForm" prop-name="lga_id"></show-error>
                    </div>
            </div>
                                        
            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('school.school')}}</label>
                    <v-select :options="schools" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': beneficiaryForm.errors.has('school_id') }" name="school_id" v-model="beneficiaryForm.school_id"></v-select>
                    <show-error :form-name="beneficiaryForm" prop-name="school_id"></show-error>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <file-upload-input :button-text="trans('general.excel')" :token="beneficiaryForm.upload_token" module="beneficiary" :clear-file="clear_message_attachment" :module-id="beneficiaryForm.id"></file-upload-input>    
                </div>
            </div>
                                        
                                        
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-4">
                    <button-spinner
                        :is-loading="beneficiaryForm.isLoading" 
                        :disabled="beneficiaryForm.isLoading"
                        
                        class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right"
                        style="height: 39px; color: #fff;"
                        >
                        <span>{{trans('general.save')}}</span>
                    </button-spinner>

                    <router-link to="/beneficiaries" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}</router-link>
        
                </div>
            </div>
        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import fileUploadInput from '../../components/file-upload-input'
    import uuid from 'uuid/v4'

    export default {
        components : { fileUploadInput },
        data() {
            return {
                beneficiaryForm: new Form({
                    isLoading: false,
                    id: '',
                    upload_token: '',
                    type: 1,
                    cohort_id: '',
                    school_id: '',
                    lga_id: ''
                }),
                cohorts: [],
                lgas: [],
                schools: [],
                clear_message_attachment: false,
            };
        },
        mounted(){
            if(!helper.hasPermission('upload-selected-beneficiaries')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
            this.beneficiaryForm.upload_token = uuid();
            this.fetchPreRequisites();
        },
         methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cohorts = response.cohorts;
                        this.lgas = response.lgas;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                this.storeUpload();
            },
            storeUpload(){
                this.beneficiaryForm.isLoading = true;
                this.beneficiaryForm.post('/api/beneficiaries/import')
                    .then(response => {
                        this.beneficiaryForm.isLoading = false;
                        toastr.success(response.message);
                        this.clear_message_attachment = true;
                        this.$router.push('/beneficiaries');
                    })
                    .catch(error => {
                        this.beneficiaryForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            getSchools(event){
                axios.get('/api/schools-by-lga-id/' + event.id)
                    .then(response => response.data)
                    .then(response => {
                        this.schools = response.schools;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            }
        }
    }
</script>
