<template>
    <section class="row flexbox-container">
      <div class="col-xl-7 col-md-8 col-12 d-flex justify-content-center">
        <div class="card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
          <div class="card-content">
            <div class="card-body text-center">
              <img src="images/pages/maintenance-2.png" class="img-fluid align-self-center" alt="branding logo">
              <h1 class="font-large-2 my-1">Maintenance Mode</h1>
              <p class="px-2">
                {{getConfig('maintenance_mode_message')}}
              </p>
              <router-link to="/" class="btn btn-primary btn-lg mt-1">{{trans('general.back')}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

</template>

<script>
    export default {
        mounted() {
            if(!helper.getConfig('maintenance_mode') || !helper.isAuth() || helper.hasRole('admin'))
                this.$router.push('/home');

            this.$store.dispatch('resetAuthUserDetail');
        },
        methods:{
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
