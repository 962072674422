<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Edit LGA
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Settings</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">LGAs</a>
                                </li>
                                <li class="breadcrumb-item active">Edit</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/setting/lga')"><i class="feather icon-check-circle"></i> LGA</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Edit LGA</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <lga-form :id="id"></lga-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import lgaForm from './form';

    export default {
        components : { lgaForm },
        data() {
            return {
                id:this.$route.params.id
            }
        },
        mounted(){
            if(!helper.hasPermission('access-settings')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
        }
    }
</script>
