var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("permission.assign_permission")) +
                  "\n                     "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.trans("permission.assign_permission"))),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/configuration/permission")
                  },
                },
              },
              [
                _c("i", { staticClass: "feather icon-lock" }),
                _vm._v(" " + _vm._s(_vm.trans("permission.permission"))),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("permission.assign_permission"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "table-responsive m-b-20" }, [
                      _c("table", { staticClass: "table table-hover" }, [
                        _c("thead", [
                          _c(
                            "tr",
                            [
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.trans("permission.permission"))
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.roles, function (role) {
                                return _c(
                                  "th",
                                  { staticClass: "text-center" },
                                  [_vm._v(_vm._s(_vm.toWord(role.name)))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.permissions, function (permission) {
                            return _c(
                              "tr",
                              [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.toWord(permission.name))),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.roles, function (role) {
                                  return _c(
                                    "td",
                                    { staticClass: "text-center" },
                                    [
                                      _c("switches", {
                                        attrs: {
                                          theme: "bootstrap",
                                          color: "success",
                                        },
                                        model: {
                                          value:
                                            _vm.assignPermissionForm.data[
                                              role.id
                                            ][permission.id],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.assignPermissionForm.data[
                                                role.id
                                              ],
                                              permission.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "assignPermissionForm.data[role.id][permission.id]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary waves-effect waves-light mr-1 mb-1",
                        attrs: { type: "button" },
                        on: { click: _vm.savePermission },
                      },
                      [_vm._v(_vm._s(_vm.trans("general.save")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-danger waves-effect waves-light mr-1 mb-1",
                        attrs: { to: "/configuration/permission" },
                      },
                      [_vm._v(_vm._s(_vm.trans("general.back")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Permissions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }