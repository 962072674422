<template>
    <div>
        <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Home</h2>
                    <div class="breadcrumb-wrapper col-12">
                        
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    Home
                                </li>
                            </ol> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
            <div class="form-group breadcrum-right">
                <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-settings"></i></button>
                    <div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item" href="#">Chat</a><a class="dropdown-item" href="#">Email</a><a class="dropdown-item" href="#">Calendar</a></div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
            <h2 class="content-header-title">Statistics</h2>
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-primary p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-primary font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/beneficiaries?type=selected&cohort=all" class="primary">
                              <h2 class="text-bold-700 mt-1">{{ selected_beneficiaries }}</h2>
                              <p class="mb-0">Total Beneficiaries Selected</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/beneficiaries?type=enrolled&cohort=all" class="success">
                              <h2 class="text-bold-700 mt-1">{{ enrolled_beneficiaries }}</h2>
                              <p class="mb-0">Total Beneficiaries Enrolled</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-primary p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-check text-primary font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/beneficiaries?type=validated&cohort=all" class="primary">
                              <h2 class="text-bold-700 mt-1">{{ validated_beneficiaries }}</h2>
                              <p class="mb-0">Total Beneficiaries Validated</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-database text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/attendances?type=above&cohort=all&cycle=all&term=all" class="success">
                              <h2 class="text-bold-700 mt-1">{{ attendance_above_70 }}</h2>
                              <p class="mb-0">Attendances (70% and above)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-database text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/attendances?type=below&cohort=all&cycle=all&term=all" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ attendance_below_70 }}</h2>
                              <p class="mb-0">Beneficiaries Attendances (Below 70%)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/payments?type=paid&cohort=all&cycle=all&trench=all" class="success">
                              <h2 class="text-bold-700 mt-1">{{ paid_beneficiaries }}</h2>
                              <p class="mb-0">Total Beneficiaires Paid</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-warning p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-warning font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/statistic/payments?type=not_paid&cohort=all&cycle=all&trench=all" class="warning">
                              <h2 class="text-bold-700 mt-1">{{ not_paid_beneficiaries }}</h2>
                              <p class="mb-0">Total Beneficiaries Not Paid</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
            </div>

          <div class="row" v-if="userRole == 'admin' || userRole == 'state cct'">
              <div class="col-lg-12 col-md-6 col-12">
                  <div class="card">
                      <div class="card-header d-flex justify-content-between align-items-end">
                          <h4 class="card-title">Selected Beneficiaries</h4>
                          <p class="font-medium-5 mb-0"><i class="feather icon-settings text-muted cursor-pointer"></i></p>
                      </div>
                      <div class="card-content">
                          <div class="card-body pb-0">
                              <beneficiaryChart :data="getChartData" :label="getChartLabel" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
        </section>
   
   </div>
    </div>
</template>

<script>
import beneficiaryChart from '../../components/beneficiary-chart'
    export default {
        components: { beneficiaryChart },
        data() {
            return {
                chartData: [],
                chartLabel: [],
                selected_beneficiaries: 0,
                enrolled_beneficiaries: 0,
                validated_beneficiaries: 0,
                attendance_above_70: 0,
                attendance_below_70: 0,
                paid_beneficiaries: 0,
                not_paid_beneficiaries: 0,
                color_themes: [],
                directions: [],
                sidebar: [],
                locales: [],
                role: [],
                userRole: '',
                preferenceForm: new Form({
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                },false),
                user_preference: {
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                }
            }
        },
        mounted(){
            if(this.$route.query.reload)
                window.location = window.location.pathname;
            axios.get('/api/dashboard')
                .then(response => response.data)
                .then(response => {
                    this.selected_beneficiaries = response.selected_beneficiaries;
                    this.enrolled_beneficiaries = response.enrolled_beneficiaries;
                    this.validated_beneficiaries = response.validated_beneficiaries;
                    this.attendance_above_70 = response.attendance_above_70;
                    this.attendance_below_70 = response.attendance_below_70;
                    this.paid_beneficiaries = response.paid_beneficiaries;
                    this.not_paid_beneficiaries = response.not_paid_beneficiaries;
                    this.chartLabel = response.labels;
                    this.chartData = response.data;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })

            axios.get('/api/user/preference/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.color_themes = response.color_themes;
                    this.directions = response.directions;
                    this.sidebar = response.sidebar;
                    this.locales = response.locales;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
            this.userRole = this.getRole()[0];
        },
        methods: {
            getStatus(todo){
                return todo.status ? ('<i class="fa fa-check font-small-3 text-success mr-50"></i>'+i18n.todo.complete+'</span>') : ('<i class="fa fa-circle font-small-3 text-danger mr-50"></i>'+i18n.todo.incomplete+'</span>') ;
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            getRole(role){
              return helper.getRole(role);
            },
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            updatePreference(){
                this.preferenceForm.post('/api/user/preference')
                    .then(response => {
                        toastr.success(response.message);

                        $('#theme').attr('href','/css/colors/'+this.preferenceForm.color_theme+'.css');

                        if(this.user_preference.direction != this.preferenceForm.direction || this.user_preference.sidebar != this.preferenceForm.sidebar || this.user_preference.locale != this.preferenceForm.locale)
                            location.reload();
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    })
            }
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          moment(date) {
            return helper.formatDate(date);
          },
          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
        computed: {
          getChartData() {
            return this.chartData
          },
          getChartLabel() {
            return this.chartLabel
          }
        }
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>