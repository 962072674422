var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("beneficiary.beneficiary")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/beneficiary" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("beneficiary.beneficiary")) +
                          "\n                                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("beneficiary.beneficiary_file_upload")) +
                      "\n                            "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _vm.hasPermission("list-beneficiaries")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/beneficiaries")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "feather icon-list" }),
                    _vm._v(
                      " " + _vm._s(_vm.trans("beneficiary.beneficiary_list"))
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.trans("beneficiary.beneficiary_file_upload"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.proceed.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    return _vm.beneficiaryForm.errors.clear($event.target.name)
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 mb-1" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-primary btn-sm waves-effect waves-float waves-light pull-left mr-1",
                        attrs: {
                          href: "/files/new-beneficiaries.xlsx",
                          download: "",
                        },
                      },
                      [
                        _c("i", { staticClass: "fas fa-download" }),
                        _vm._v(
                          " " + _vm._s(_vm.trans("general.download_new_sample"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-primary btn-sm waves-effect waves-float waves-light pull-left",
                        attrs: {
                          href: "/files/existing-beneficiaries.xlsx",
                          download: "",
                        },
                      },
                      [
                        _c("i", { staticClass: "fas fa-download" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.trans("general.download_existing_sample")
                            )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-2 col-md-2 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Type")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.beneficiaryForm.type,
                                expression: "beneficiaryForm.type",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.beneficiaryForm.errors.has("type"),
                            },
                            attrs: { name: "type" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.beneficiaryForm,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "1" } }, [
                              _vm._v("New"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "2" } }, [
                              _vm._v("Existing"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.beneficiaryForm,
                            "prop-name": "type",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-2 col-md-2 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("beneficiary.cohort"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.beneficiaryForm.cohort_id,
                                expression: "beneficiaryForm.cohort_id",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.beneficiaryForm.errors.has("cohort_id"),
                            },
                            attrs: { name: "cohort_id" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.beneficiaryForm,
                                  "cohort_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.trans("beneficiary.cohort"))),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.cohorts, function (cohort) {
                              return _c(
                                "option",
                                { domProps: { value: cohort.id } },
                                [_vm._v(_vm._s(cohort.name))]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.beneficiaryForm,
                            "prop-name": "cohort_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("beneficiary.lga"))),
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          class: {
                            "form-control is-invalid":
                              _vm.beneficiaryForm.errors.has("lga_id"),
                          },
                          attrs: {
                            options: _vm.lgas,
                            label: "name",
                            name: "lga_id",
                          },
                          on: { input: _vm.getSchools },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function ({ id, name, status }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      style:
                                        status == 1
                                          ? "color: green; font-weight: bold"
                                          : "",
                                    },
                                    [_vm._v(_vm._s(name))]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.beneficiaryForm.lga_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.beneficiaryForm, "lga_id", $$v)
                            },
                            expression: "beneficiaryForm.lga_id",
                          },
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.beneficiaryForm,
                            "prop-name": "lga_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("school.school"))),
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          class: {
                            "form-control is-invalid":
                              _vm.beneficiaryForm.errors.has("school_id"),
                          },
                          attrs: {
                            options: _vm.schools,
                            reduce: (name) => name.id,
                            label: "name",
                            name: "school_id",
                          },
                          model: {
                            value: _vm.beneficiaryForm.school_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.beneficiaryForm, "school_id", $$v)
                            },
                            expression: "beneficiaryForm.school_id",
                          },
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.beneficiaryForm,
                            "prop-name": "school_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("file-upload-input", {
                          attrs: {
                            "button-text": _vm.trans("general.excel"),
                            token: _vm.beneficiaryForm.upload_token,
                            module: "beneficiary",
                            "clear-file": _vm.clear_message_attachment,
                            "module-id": _vm.beneficiaryForm.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-8 offset-md-4" },
                    [
                      _c(
                        "button-spinner",
                        {
                          staticClass:
                            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                          staticStyle: { height: "39px", color: "#fff" },
                          attrs: {
                            "is-loading": _vm.beneficiaryForm.isLoading,
                            disabled: _vm.beneficiaryForm.isLoading,
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.trans("general.save"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                          attrs: { to: "/beneficiaries" },
                        },
                        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }