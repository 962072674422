<template>
    <form @submit.prevent="proceed" @keydown="trenchForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="form-group">
                    <label for="">Class</label>
                    <select class="form-control" :class="{ 'is-invalid': trenchForm.errors.has('class_id') }" name="class_id" v-model="trenchForm.class_id">
                        <option value="">Class</option>
                        <option v-for="clas in classes" :value="clas.id">{{clas.name}}</option>
                    </select>
                    <show-error :form-name="trenchForm" prop-name="class_id"></show-error>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="form-group">
                    <label for="">Term</label>
                    <select class="form-control" :class="{ 'is-invalid': trenchForm.errors.has('term_id') }" name="term_id" v-model="trenchForm.term_id">
                        <option value="">Term</option>
                        <option v-for="term in terms" :value="term.id">{{term.name}}</option>
                    </select>
                    <show-error :form-name="trenchForm" prop-name="term_id"></show-error>
                </div>
            </div>


            <div class="col-lg-3 col-sm-6">
                <div class="form-group">
                    <label for="">Name</label>
                    <input class="form-control" :class="{ 'is-invalid': trenchForm.errors.has('name') }" type="text" value="" v-model="trenchForm.name" name="name" placeholder="Name">
                    <show-error :form-name="trenchForm" prop-name="name"></show-error>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="form-group">
                    <label for="">Amount</label>
                    <input class="form-control" :class="{ 'is-invalid': trenchForm.errors.has('amount') }" type="text" value="" v-model="trenchForm.amount" name="amount" placeholder="Amount">
                    <show-error :form-name="trenchForm" prop-name="amount"></show-error>
                </div>
            </div>

        </div>
        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
            <span v-if="id">{{trans('general.update')}}</span>
            <span v-else>{{trans('general.save')}}</span>
        </button>
        <router-link to="/setting/trench" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-show="id">{{trans('general.cancel')}}</router-link>
        <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" @click="$emit('cancel')">{{trans('general.cancel')}}</button>
    </form>
</template>


<script>
    export default {
        data() {
            return {
                trenchForm: new Form({
                    class_id: '',
                    term_id: '',
                    name: '',
                    amount: '',
                }),
                classes: []
            };
        },
        props: ['id'],
        mounted() {
            this.fetchPreRequisites();
            if(this.id)
                this.getTrench();
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.classes = response.classes;
                        this.terms = response.terms;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updateTrench();
                else
                    this.storeTrench();
            },
            storeTrench(){
                this.trenchForm.post('/api/setting/trench')
                    .then(response => {
                        toastr.success(response.message);
                        this.$emit('completed')
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getTrench(){
                axios.get('/api/setting/trench/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.trenchForm.class_id = response.class_id;
                        this.trenchForm.term_id = response.term_id;
                        this.trenchForm.name = response.name;
                        this.trenchForm.amount = response.amount;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/setting/trench');
                    });
            },
            updateTrench(){
                this.trenchForm.patch('/api/setting/trench/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/setting/trench');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
