<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('ip_filter.ip_filter')}}
                            <span class="card-subtitle" v-if="ip_filters">{{trans('general.total_result_found',{'count' : ip_filters.total})}}</span>
                            <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('ip_filter.ip_filter')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="showCreatePanel = !showCreatePanel"><i class="feather icon-plus"></i> {{trans('ip_filter.add_new_ip_filter')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <transition name="fade">
                            <div class="card"  v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('ip_filter.add_new_ip_filter')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <ip-filter-form @completed="getIpFilters" @cancel="showCreatePanel = !showCreatePanel"></ip-filter-form>
                                </div>
                            </div>
                        </div>
                        </transition>
                    </div>
                </div>
            </section>

            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                        
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="ip_filters.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('ip_filter.start_ip')}}</th>
                                            <th>{{trans('ip_filter.end_ip')}}</th>
                                            <th>{{trans('ip_filter.description')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="ip_filter in ip_filters.data">
                                            <td v-text="ip_filter.start_ip"></td>
                                            <td v-text="ip_filter.end_ip"></td>
                                            <td v-text="ip_filter.description"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-info btn-sm" v-tooltip="trans('ip_filter.edit_ip_filter')" @click.prevent="editIpFilter(ip_filter)"><i class="feather icon-edit"></i></button>
                                                    <button class="btn btn-danger btn-sm" :key="ip_filter.id" v-confirm="{ok: confirmDelete(ip_filter)}" v-tooltip="trans('ip_filter.delete_ip_filter')"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!ip_filters.total" module="ip_filter" title="module_info_title" description="module_info_description" icon="filter"></module-info>
                            <pagination-record :page-length.sync="filterIpFilterForm.page_length" :records="ip_filters" @updateRecords="getIpFilters" @change.native="getIpFilters"></pagination-record>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            
        </div>

        
    </div>
</template>

<script>
    import ipFilterForm from './form'

    export default {
        components : { ipFilterForm },
        data() {
            return {
                ip_filters: {
                    total: 0,
                    data: []
                },
                filterIpFilterForm: {
                    page_length: helper.getConfig('page_length')
                },
                showCreatePanel: false
            };
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('ip_filter')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getIpFilters();
        },
        methods: {
            getIpFilters(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterIpFilterForm);
                axios.get('/api/ip-filter?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.ip_filters = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            editIpFilter(ip_filter){
                this.$router.push('/configuration/ip-filter/'+ip_filter.id+'/edit');
            },
            confirmDelete(ip_filter){
                return dialog => this.deleteIpFilter(ip_filter);
            },
            deleteIpFilter(ip_filter){
                axios.delete('/api/ip-filter/'+ip_filter.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getIpFilters();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            }
        }
    }
</script>
