<template>
    <div class="row">
      <div class="col-12">
        <div class="profile-header mb-2">
          <div class="relative">
            <div class="cover-container">
              <img class="img-fluid bg-cover rounded-0 w-100" src="/images/profile/user-uploads/cover.jpg" alt="User Profile Image">
            </div>
            <div class="profile-img-container d-flex align-items-center justify-content-between">
              <img :src="getAvatar(user)" class="rounded-circle img-border box-shadow-1" alt="Card image">
              <div class="float-right">
                <button type="button" class="btn btn-icon btn-icon rounded-circle btn-primary mr-1">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button type="button" class="btn btn-icon btn-icon rounded-circle btn-primary">
                  <i class="feather icon-settings"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center profile-header-nav">
            <nav class="navbar navbar-expand-sm w-100 pr-0">
              <button class="navbar-toggler pr-0" type="button" data-toggle="collapse" data-target="navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="feather icon-align-justify"></i></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav justify-content-around w-75 ml-sm-auto">
                      <li class="nav-item px-sm-0">
                        <router-link :to="`/user/${id}/basic`" :class="[menu === 'basic' ? 'active' : '', 'nav-link font-small-3']"><i class="fas fa-user"></i> {{trans('user.basic')}}
            </router-link>
                      </li>
                      <li class="nav-item px-sm-0">
                          <router-link :to="`/user/${id}/contact`" :class="[menu === 'contact' ? 'active' : '', 'nav-link font-small-3']"><i class="fas fa-phone"></i> {{trans('user.contact')}}
            </router-link>
                      </li>
                      <li class="nav-item px-sm-0">
                          <router-link :to="`/user/${id}/avatar`" :class="[menu === 'avatar' ? 'active' : '', 'nav-link font-small-3']"><i class="far fa-address-book"></i> {{trans('user.avatar')}}
                            </router-link>
                      </li>
                      <li class="nav-item px-sm-0">
                          <router-link :to="`/user/${id}/social`" :class="[menu === 'social' ? 'active' : '', 'nav-link font-small-3']"><i class="fas fa-share-alt"></i> {{trans('user.social')}}
            </router-link>
                      </li>
                      <li class="nav-item px-sm-0">
                          <router-link v-if="id != getAuthUser('id')" :to="`/user/${id}/password`" :class="[menu === 'password' ? 'active' : '', 'nav-link font-small-3']"><i class="fas fa-key"></i>
                                {{trans('user.reset_password')}}
            </router-link>
                      </li>
                      <li class="nav-item px-sm-0">
                          <router-link v-if="getConfig('email_template')" :to="`/user/${id}/email`" :class="[menu === 'email' ? 'active' : '', 'nav-link font-small-3']"><i class="fas fa-envelope"></i>
                    {{trans('user.email')}}
            </router-link>
                      </li>
                  </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
    export default {

        props: {
            menu: {
                default: ''
            },
            id: {
                required: true
            },
            user: {
                default: ''
            }
        },
        mounted(){
        },
        methods:{
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            getConfig(config){
                return helper.getConfig(config);
            },
            getAvatar(user){
                return  helper.getAvatar(user);
            }
        },
        watch: {
            user(newVal){
                this.user = newVal;
            }
        },
    }
</script>
