<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('template.edit_template')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">{{trans('template.edit_template')}}</a>
                                </li>
                                <li class="breadcrumb-item active">Edit</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/email-template')"><i class="feather icon-mail"></i> {{trans('template.template')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('template.edit_template')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <form @submit.prevent="submit" @keydown="templateForm.errors.clear($event.target.name)">
                                <div class="form-group">
                                    <label for="">{{trans('template.subject')}}</label>
                                    <input class="form-control" type="text" value="" v-model="templateForm.subject" name="subject" :placeholder="trans('template.subject')">
                                    <show-error :form-name="templateForm" prop-name="subject"></show-error>
                                </div>
                                <div class="form-group">
                                    <html-editor name="body" :model.sync="templateForm.body" isUpdate="true" @clearErrors="templateForm.errors.clear('body')"></html-editor>
                                    <show-error :form-name="templateForm" prop-name="body"></show-error>
                                </div>
                                
                                <div class="help-block">{{trans('template.available_fields')}}: {{fields}}</div>
                                <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">{{trans('general.save')}}</button>
                                <button type="button" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" @click="$router.push('/email-template')">{{trans('general.cancel')}}</button>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import htmlEditor from '../../components/html-editor'

    export default {
        components : { htmlEditor },
        data() {
            return {
                id:this.$route.params.id,
                templateForm: new Form({
                    subject: '',
                    body: '',
                }),
                fields: ''
            }
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('email_template')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            axios.get('/api/email-template/'+this.id)
                .then(response => response.data)
                .then(response => {
                    this.templateForm.subject = response.email_template.subject;
                    this.templateForm.body = response.email_template.body;
                    this.fields = response.fields;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                    this.$router.push('/email-template');
                })
        },
        methods: {
            submit(){
                this.templateForm.patch('/api/email-template/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/email-template');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
