<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.beneficiary')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/beneficiaries">
                                        {{ trans('beneficiary.beneficiary') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('beneficiary.enroll_beneficiary') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/beneficiaries')"><i class="feather icon-list"></i> {{trans('beneficiary.beneficiary_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('beneficiary.enroll_beneficiary')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <beneficiaries-form :id="id" :enrollment="enrollment"></beneficiaries-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import beneficiariesForm from './form';

    export default {
        components : { beneficiariesForm },
        data() {
            return {
                id:this.$route.params.id,
                enrollment:this.$route.name
            }
        },
        mounted(){
            if(!helper.hasPermission('enroll-beneficiaries')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

        }
    }
</script>
