<template>
    <!-- <section id="wrapper">
        <div class="login-register">
            <div class="login-box card">
                <div class="card-body">
                    <h3 class="box-title m-b-20">{{trans('auth.tnc')}}</h3>
                    <p>Here goes terms and conditions!</p>
                     <div class="text-center m-t-20">
                        <div class="col-xs-12">
                            <router-link class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" to="/">{{trans('general.back')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section id="start-kit-pages" class="card">
    <div class="card-header">
        <h4 class="card-title">Start Kit Pages</h4>
        <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
        <div class="heading-elements">
            <ul class="list-inline mb-0">
                <li><a data-action="collapse"><i class="feather icon-chevron-down"></i></a></li>
                <li><a data-action="reload"><i class="feather icon-rotate-cw"></i></a></li>
                <li><a data-action="close"><i class="feather icon-x"></i></a></li>
            </ul>
        </div>
    </div>
    <div class="card-content collapse show" aria-expanded="true">
        <div class="card-body">

            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Pages</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>2 Columns</th>
                            <td><a href="https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-2-columns.html" target="_blank">sk-layout-2-columns.html</a></td>
                        </tr>
                        <tr>
                            <th>Fixed Navbar</th>
                            <td><a href="https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-fixed-navbar.html" target="_blank">sk-layout-fixed-navbar.html</a></td>
                        </tr>
                        <tr>
                            <th>Floating Navbar</th>
                            <td><a href="https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-floating-navbar.html" target="_blank">sk-layout-floating-navbar.html</a></td>
                        </tr>
                        <tr>
                            <th>Fixed Layout</th>
                            <td><a href="https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-fixed.html" target="_blank">sk-layout-fixed.html</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
</template>
<script>
    export default {
        mounted(){

            if(!helper.featureAvailable('terms_and_conditions')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
        }
    }
</script>
