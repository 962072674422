var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "main-menu menu-fixed menu-dark menu-accordion menu-shadow semi-dark",
      attrs: { "data-scroll-to-active": "true" },
    },
    [
      _c("div", { staticClass: "navbar-header" }, [
        _c("ul", { staticClass: "nav navbar-nav flex-row" }, [
          _c("li", { staticClass: "nav-item mr-auto" }, [
            _c(
              "a",
              {
                staticClass: "navbar-brand",
                attrs: { href: "dashboard-ecommerce" },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "background-position": "-120px -10px" } },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.getMainLogo,
                        alt: "",
                        height: "24",
                        width: "35",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    staticClass: "brand-text mb-0",
                    staticStyle: { color: "rgb(40, 199, 111)" },
                  },
                  [_vm._v(_vm._s(_vm.companyName))]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shadow-bottom" }),
      _vm._v(" "),
      _c("div", { staticClass: "main-menu-content" }, [_c("main-menu")], 1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item nav-toggle" }, [
      _c(
        "a",
        {
          staticClass: "nav-link modern-nav-toggle pr-0 shepherd-modal-target",
          attrs: { "data-toggle": "collapse" },
        },
        [
          _c("i", {
            staticClass:
              "icon-x d-block d-xl-none font-medium-4 toggle-icon feather icon-disc",
            staticStyle: { color: "rgb(40, 199, 111)" },
          }),
          _c("i", {
            staticClass:
              "toggle-icon icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon feather",
            staticStyle: { color: "rgb(40, 199, 111)" },
            attrs: { "data-ticon": "icon-disc", tabindex: "0" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }