<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('permission.assign_permission')}}
                         </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Permissions</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('permission.assign_permission')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/configuration/permission')"><i class="feather icon-lock"></i> {{trans('permission.permission')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{trans('permission.assign_permission')}}</h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive m-b-20">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>{{trans('permission.permission')}}</th>
                                            <th v-for="role in roles" class="text-center">{{toWord(role.name)}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="permission in permissions">
                                            <td>{{toWord(permission.name)}}</td>
                                            <td v-for="role in roles" class="text-center">
                                                <switches v-model="assignPermissionForm.data[role.id][permission.id]" theme="bootstrap" color="success"></switches>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button type="button" class="btn btn-primary waves-effect waves-light mr-1 mb-1" @click="savePermission">{{trans('general.save')}}</button>
                            <router-link to="/configuration/permission" class="btn btn-danger waves-effect waves-light mr-1 mb-1">{{trans('general.back')}}</router-link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            
        </div>

        
    </div>
</template>

<script>
    import switches from 'vue-switches'

    export default {
        components: {switches},
        data() {
            return {
                roles: [],
                permissions: [],
                assignPermissionForm: new Form({
                    data: {}
                })
            }
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            axios.get('/api/permission/assign/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.permissions = response.permissions;
                    this.roles = response.roles;
                    this.assignPermissionForm.data = response.data;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods:{
            savePermission(){
                axios.post('/api/permission/assign',{
                    data: this.assignPermissionForm.data
                })
                .then(response => response.data)
                .then(response => {
                    toastr.success(response.message);
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
            },
            toWord(str){
                return helper.toWord(str);
            }
        }
    }
</script>
