var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "input-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", disabled: _vm.isUploadDisabled },
            on: { click: _vm.launchFilePicker },
          },
          [
            _c("i", { staticClass: "fas fa-upload" }),
            _vm._v(" " + _vm._s(_vm.buttonText)),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [{ name: "uploader", rawName: "v-uploader" }],
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file" },
      }),
      _vm._v(" "),
      _c("file-upload-progress", {
        staticStyle: { "margin-top": "10px" },
        attrs: { progress: _vm.progress },
      }),
      _vm._v(" "),
      _vm.progress || _vm.isFileSelected
        ? _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.trans("upload.cancel_upload"),
                  expression: "trans('upload.cancel_upload')",
                },
              ],
              staticClass: "btn btn-danger btn-sm m-t-10",
              attrs: { type: "button" },
              on: { click: _vm.cancelUpload },
            },
            [_c("i", { staticClass: "fas fa-trash" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.uploaded_files
        ? _c(
            "ul",
            { staticClass: "upload-file-list m-t-20" },
            _vm._l(_vm.uploaded_files, function (uploaded_file) {
              return _c("li", [
                _c("i", { staticClass: "fas fa-file" }),
                _vm._v(
                  " " + _vm._s(uploaded_file.user_filename) + "\n          "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "confirm",
                        rawName: "v-confirm",
                        value: { ok: _vm.confirmDelete(uploaded_file) },
                        expression: "{ok: confirmDelete(uploaded_file)}",
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.trans("general.delete_upload"),
                        expression: "trans('general.delete_upload')",
                      },
                    ],
                    key: uploaded_file.id,
                    staticClass: "btn btn-danger btn-xs",
                  },
                  [_c("i", { staticClass: "fas fa-trash" })]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }