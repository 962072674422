<template>
    <!-- BEGIN: Header -->
    <nav class="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-light navbar-shadow bg-success navbar-dark fixed-top">
    <div class="navbar-wrapper">
        <div class="navbar-container content">
            <div class="navbar-collapse" id="navbar-mobile">
                <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                    <ul class="nav navbar-nav">
                        <li class="nav-item mobile-menu d-xl-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ficon feather icon-menu"></i></a></li>
                    </ul>
                    <ul class="nav navbar-nav bookmark-icons">
                        
                        <li class="nav-item d-none d-lg-block" v-if="getConfig('todo') && hasPermission('access-todo')">
                            <router-link class="nav-link" to="/todo" data-toggle="tooltip" data-placement="top" :title="trans('todo.todo')"><i class="ficon feather icon-check-square"></i></router-link>
                        </li>
                        
                        <li class="nav-item d-none d-lg-block" v-if="getConfig('message') && hasPermission('access-message')">
                            <router-link class="nav-link" to="/message" data-toggle="tooltip" data-placement="top" :title="trans('message.message')"><i class="ficon feather icon-mail"></i></router-link>
                        </li>
                        <li class="nav-item d-none d-lg-block" v-if="getConfig('calendar') && hasPermission('access-calendar')">
                            <router-link class="nav-link" to="/calendar" data-toggle="tooltip" data-placement="top" title="Calender"><i class="ficon feather icon-calendar"></i></router-link>
                        </li>
                    </ul>
                </div>
                <ul class="nav navbar-nav float-right">
                    <li class="nav-item d-none d-lg-block">
                        <a class="nav-link nav-link-expand"><i class="ficon feather icon-maximize"></i></a>
                    </li>

                    <li class="dropdown dropdown-notification nav-item"><a class="nav-link nav-link-label" href="#" data-toggle="dropdown" v-if="notifications.length"><i class="ficon feather icon-bell"></i><span class="badge badge-pill badge-primary badge-up">{{ notifications.length }}</span></a>
                        <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                            <li class="dropdown-menu-header">
                                <div class="dropdown-header m-0 p-2">
                                    <h3 class="white">{{ notifications.length }} New</h3><span class="grey darken-2">Notifications</span>
                                </div>
                            </li>
                            <li v-for="notification in notifications" class="scrollable-container media-list">

                                <a class="d-flex justify-content-between" href="#" @click="markAsRead(notification)">
                                    <div class="media d-flex align-items-start">
                                        <div class="media-left"><i :class="notification.data.icon"></i></div>
                                        <div class="media-body">
                                            <h6 class="primary media-heading">{{ notification.data.title }}</h6><small class="notification-text"> {{ notification.data.message }}</small>
                                        </div><small>
                                            <time class="media-meta" datetime="2015-06-11T18:29:20+08:00">{{ ago }}</time></small>
                                    </div>
                                </a>
                            </li>
                            <li class="dropdown-menu-footer"><a class="dropdown-item p-1 text-center" href="javascript:void(0)">Read all notifications</a></li>
                        </ul>
                    </li>
                    <li class="dropdown dropdown-user nav-item"><a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                            <div class="user-nav d-sm-flex d-none"><span class="user-name text-bold-600">{{getAuthUser('full_name')}}</span><span class="user-status">{{getAuthUser('email')}}</span></div><span><img class="round" :src="getAuthUser('avatar')" alt="user" height="40" width="40" /></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link to="/profile" class="dropdown-item"><i class="feather icon-user"></i> {{trans('user.view_profile')}}</router-link>
                            <router-link to="/change-password" class="dropdown-item"><i class="feather icon-lock"></i> {{trans('user.change_password')}}</router-link>
                            
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" @click.prevent="logout"><i class="feather icon-power"></i> {{trans('auth.logout')}}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- END: Header-->
    
</template>

<script>
    export default {
        data() {
            return {
                notifications: false
            }
        },

        created() {

            axios.get('/api/user/' + this.getAuthUser('id') + '/notifications')
                 .then(response => this.notifications = response.data);
        },

        mounted() {
        },
        methods : {
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            getConfig(name){
                return helper.getConfig(name);
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },

            markAsRead(notification){
                axios.delete('/api/user/' + this.getAuthUser('id') + '/notifications/' + notification.id);
            }
        },
        computed: {
            getMainLogo(){
                if(helper.getConfig('main_logo'))
                    return '/'+helper.getConfig('main_logo');
                else
                    return '/images/default_main_logo.png';
            },
            getSidebarLogo(){
                if(helper.getConfig('sidebar_logo'))
                    return '/'+helper.getConfig('sidebar_logo');
                else
                    return '/images/default_sidebar_logo.png';
            },
            ago(notification) {
                return moment(notification).fromNow();
            }
        }
    }
</script>
