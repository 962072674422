<template>
  <div>
    <canvas id="beneficiary-chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
Chart.plugins.register(ChartJSPluginDatalabels)

export default {
  props: {
      data: {
        type:[Array,Object],
        required: true,
      },
      label: {
        type:[Array,Object],
        required: true
      }
    },
  data() {
    return {
      beneficiaryChartData: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "Selected Beneficiaries",
              data: [],
              backgroundColor: "rgba(71, 183,132,.5)",
              borderColor: "#47b784",
              borderWidth: 3
            },
            
          ]
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          },
          plugins: {
              datalabels: {
                  anchor: 'end',
                  align: 'top',
                  formatter: Math.round,
                  font: {
                      weight: 'bold'
                  }
              }
          }
        }

    },
    }
  },

  created(){
    setTimeout(() => {
              const ctx = document.getElementById('beneficiary-chart');
    new Chart(ctx, this.beneficiaryChartData);
            }, 2000)
    
  },

  watch: {
    label: {
      immediate: true,
      handler (newVal, oldVal) {
        this.beneficiaryChartData.data.labels = newVal;
      }
    },
    data: {
      immediate: true,
      handler (newVal, oldVal) {
        this.beneficiaryChartData.data.datasets[0].data = newVal;
      }
    },
  },
}
</script>