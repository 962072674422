var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("calendar.calendar")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.trans("calendar.calendar"))),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c("div", { staticClass: "dropdown" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-sm pull-right",
                  on: {
                    click: function ($event) {
                      _vm.showCreatePanel = !_vm.showCreatePanel
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-calendar" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-none d-sm-inline" }, [
                    _vm._v(_vm._s(_vm.trans("calendar.add_new_event"))),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showCreatePanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("calendar.add_new_event"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("event-form", {
                              on: {
                                completed: _vm.getEvents,
                                cancel: function ($event) {
                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("full-calendar", {
                ref: "calendar",
                attrs: {
                  "event-sources": _vm.eventSources,
                  config: _vm.config,
                },
                on: {
                  "event-selected": _vm.eventSelected,
                  "event-created": _vm.eventCreated,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }