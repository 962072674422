var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("app-header"),
      _vm._v(" "),
      _c("app-sidebar"),
      _vm._v(" "),
      _c("div", { staticClass: "app-content content" }, [
        _c("div", { staticClass: "content-overlay" }),
        _vm._v(" "),
        _c("div", { staticClass: "header-navbar-shadow" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            !_vm.getConfig("mode")
              ? _c("div", {
                  staticClass: "content-body",
                  domProps: { innerHTML: _vm._s(_vm.message) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("router-view"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sidenav-overlay" }),
      _vm._v(" "),
      _c("div", { staticClass: "drag-target" }),
      _vm._v(" "),
      _c("app-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }