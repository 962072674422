var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      { staticClass: "col-xl-8 col-10 d-flex justify-content-center" },
      [
        _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6 col-12 p-0" }, [
              _c("div", { staticClass: "card rounded-0 mb-0 p-2" }, [
                _c("div", { staticClass: "card-header pt-50 pb-1" }, [
                  _c("div", { staticClass: "card-title" }, [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.trans("auth.sign_up"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "px-2" }, [
                  _vm._v("Fill the below form to create a new account."),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body pt-0" }, [
                    _c(
                      "form",
                      {
                        attrs: { id: "registerform" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                          keydown: function ($event) {
                            return _vm.registerForm.errors.clear(
                              $event.target.name
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.registerForm.first_name,
                                  expression: "registerForm.first_name",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.registerForm.errors.has("first_name"),
                              },
                              attrs: {
                                type: "text",
                                name: "first_name",
                                autocomplete: "first_name",
                                placeholder: _vm.trans("auth.first_name"),
                                autofocus: "",
                              },
                              domProps: { value: _vm.registerForm.first_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.registerForm,
                                    "first_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "first_name" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.first_name"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "first_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.registerForm.last_name,
                                  expression: "registerForm.last_name",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.registerForm.errors.has("last_name"),
                              },
                              attrs: {
                                type: "text",
                                name: "last_name",
                                autocomplete: "last_name",
                                placeholder: _vm.trans("auth.last_name"),
                                autofocus: "",
                              },
                              domProps: { value: _vm.registerForm.last_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.registerForm,
                                    "last_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "last_name" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.last_name"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "last_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.registerForm.company_name,
                                  expression: "registerForm.company_name",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.registerForm.errors.has("company_name"),
                              },
                              attrs: {
                                type: "text",
                                name: "company_name",
                                autocomplete: "company_name",
                                placeholder: _vm.trans("auth.company_name"),
                                autofocus: "",
                              },
                              domProps: {
                                value: _vm.registerForm.company_name,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.registerForm,
                                    "company_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "company_name" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.company_name"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "company_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.registerForm.email,
                                  expression: "registerForm.email",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.registerForm.errors.has("email"),
                              },
                              attrs: {
                                type: "text",
                                name: "email",
                                autocomplete: "email",
                                placeholder: _vm.trans("auth.email"),
                                autofocus: "",
                              },
                              domProps: { value: _vm.registerForm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.registerForm,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "email" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.email"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _vm.getConfig("password_strength_meter")
                              ? _c(
                                  "div",
                                  [
                                    _c("password", {
                                      attrs: {
                                        name: "password",
                                        defaultClass: "form-control",
                                        placeholder: _vm.trans("auth.password"),
                                        required: false,
                                      },
                                      model: {
                                        value: _vm.registerForm.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.registerForm,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "registerForm.password",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.registerForm.password,
                                        expression: "registerForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.registerForm.errors.has("password"),
                                    },
                                    attrs: {
                                      type: "password",
                                      name: "password",
                                      placeholder: _vm.trans("auth.password"),
                                    },
                                    domProps: {
                                      value: _vm.registerForm.password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.registerForm,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "password" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.password"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "password",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.registerForm.password_confirmation,
                                  expression:
                                    "registerForm.password_confirmation",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.registerForm.errors.has(
                                  "password_confirmation"
                                ),
                              },
                              attrs: {
                                type: "password",
                                name: "password_confirmation",
                                placeholder: _vm.trans("auth.confirm_password"),
                              },
                              domProps: {
                                value: _vm.registerForm.password_confirmation,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.registerForm,
                                    "password_confirmation",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "password-confirm" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.confirm_password"))
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "password_confirmation",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("fieldset", { staticClass: "checkbox" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vs-checkbox-con vs-checkbox-primary",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.registerForm.tnc,
                                          expression: "registerForm.tnc",
                                        },
                                      ],
                                      attrs: {
                                        id: "checkbox-signup",
                                        type: "checkbox",
                                        name: "tnc",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.registerForm.tnc
                                        )
                                          ? _vm._i(_vm.registerForm.tnc, null) >
                                            -1
                                          : _vm.registerForm.tnc,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.registerForm.tnc,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.registerForm,
                                                  "tnc",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.registerForm,
                                                  "tnc",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.registerForm,
                                              "tnc",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c("span", {}, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href: "/terms-and-conditions",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.trans("auth.accept_tnc"))
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.registerForm,
                                  "prop-name": "tnc",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.getConfig("recaptcha") &&
                        _vm.getConfig("register_recaptcha")
                          ? _c("div", {
                              staticClass: "form-group g-recaptcha",
                              attrs: {
                                "data-sitekey": _vm.getConfig("recaptcha_key"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-outline-primary float-left btn-inline mb-50",
                            attrs: { to: "/login" },
                          },
                          [_vm._v(_vm._s(_vm.trans("auth.sign_in")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary float-right btn-inline mb-50",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(_vm._s(_vm.trans("auth.register")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-lg-6 d-lg-block d-none text-center align-self-center pl-0 pr-3 py-0",
      },
      [
        _c("img", {
          attrs: {
            src: "images/pages/register.jpeg",
            width: "332px",
            alt: "branding logo",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "vs-checkbox" }, [
      _c("span", { staticClass: "vs-checkbox--check" }, [
        _c("i", { staticClass: "vs-icon feather icon-check" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }