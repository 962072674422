var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.messageForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("v-select", {
            attrs: {
              label: "name",
              name: "to_user_id",
              id: "to_user_id",
              options: _vm.users,
              placeholder: _vm.trans("message.select_recipient"),
            },
            on: {
              select: function ($event) {
                return _vm.messageForm.errors.clear("to_user_id")
              },
              close: function ($event) {
                _vm.messageForm.to_user_id = _vm.selected_user.id
              },
              remove: function ($event) {
                _vm.messageForm.to_user_id = ""
              },
            },
            model: {
              value: _vm.selected_user,
              callback: function ($$v) {
                _vm.selected_user = $$v
              },
              expression: "selected_user",
            },
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.messageForm, "prop-name": "to_user_id" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.messageForm.subject,
                expression: "messageForm.subject",
              },
            ],
            staticClass: "form-control message-input",
            attrs: {
              type: "text",
              value: "",
              name: "subject",
              placeholder: _vm.trans("message.subject"),
            },
            domProps: { value: _vm.messageForm.subject },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.messageForm, "subject", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.messageForm, "prop-name": "subject" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("html-editor", {
            attrs: {
              name: "body",
              model: _vm.messageForm.body,
              isUpdate: _vm.uuid ? true : false,
            },
            on: {
              "update:model": function ($event) {
                return _vm.$set(_vm.messageForm, "body", $event)
              },
              clearErrors: function ($event) {
                return _vm.messageForm.errors.clear("body")
              },
            },
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.messageForm, "prop-name": "body" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("file-upload-input", {
            attrs: {
              "button-text": _vm.trans("message.attachment"),
              token: _vm.messageForm.upload_token,
              module: "message",
              "clear-file": _vm.clear_message_attachment,
              "module-id": _vm.messageForm.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group pull-right" },
        [
          _vm.messageForm.id
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "confirm",
                      rawName: "v-confirm",
                      value: { ok: _vm.confirmDelete(_vm.messageForm.uuid) },
                      expression: "{ok: confirmDelete(messageForm.uuid)}",
                    },
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.trans("message.delete_draft"),
                      expression: "trans('message.delete_draft')",
                    },
                  ],
                  staticClass: "btn btn-danger waves-effect waves-light",
                  attrs: { type: "button" },
                },
                [_c("i", { staticClass: "fas fa-trash" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.trans("message.save_as_draft"),
                  expression: "trans('message.save_as_draft')",
                },
              ],
              staticClass: "btn btn-info waves-effect waves-light",
              attrs: { type: "button" },
              on: { click: _vm.saveAsDraft },
            },
            [
              _c("i", { staticClass: "fas fa-edit" }),
              _vm._v(" " + _vm._s(_vm.trans("message.save_as_draft"))),
            ]
          ),
          _vm._v(" "),
          _vm.messageForm.id
            ? _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.trans("message.cancel"),
                      expression: "trans('message.cancel')",
                    },
                  ],
                  staticClass: "btn btn-warning waves-effect waves-light",
                  attrs: { to: "/message/draft" },
                },
                [
                  _c("i", { staticClass: "fas fa-times" }),
                  _vm._v(" " + _vm._s(_vm.trans("message.cancel"))),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.trans("message.send"),
                  expression: "trans('message.send')",
                },
              ],
              staticClass: "btn btn-success waves-effect waves-light",
              attrs: { type: "submit" },
            },
            [
              _c("i", { staticClass: "fas fa-paper-plane" }),
              _vm._v(" " + _vm._s(_vm.trans("message.send"))),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }