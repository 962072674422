var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      {
        staticClass:
          "col-xl-8 col-md-9 col-10 d-flex justify-content-center px-0",
      },
      [
        _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6 col-12 p-0" }, [
              _c("div", { staticClass: "card rounded-0 mb-0 px-2 py-1" }, [
                _c("div", { staticClass: "card-header pb-1" }, [
                  _c("div", { staticClass: "card-title" }, [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.trans("auth.logging_in"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      { staticClass: "float-md-left d-block mb-1" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-outline-primary btn-block px-75",
                            attrs: { to: "/login" },
                          },
                          [_vm._v(_vm._s(_vm.trans("auth.back_to_login?")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "col-lg-6 d-lg-block d-none text-center align-self-center",
      },
      [
        _c("img", {
          attrs: {
            src: "/images/pages/forgot-password.png",
            alt: "branding logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }