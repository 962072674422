<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Payments</h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item active">Payments</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-6 col-12 d-md-block d-none">
                
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> <span class="d-none d-sm-inline">{{trans('general.filter')}}</span></button>

                    <button class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push('/payment/download-payments')" v-if="hasPermission('download-payment')"><i class="feather icon-download"></i> Download Payments</button>

                    <button class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push('/payment/upload-payments')" v-if="hasPermission('upload-payment')"><i class="feather icon-file-text"></i> Upload Payments</button>

                    
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">{{trans('general.keyword')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterPaymentForm.keyword">
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="payments.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterPaymentForm.sort_by" :order="filterPaymentForm.order" @updateSortBy="value => {filterPaymentForm.sort_by = value}"  @updateOrder="value => {filterPaymentForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="payments.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>Cohort</th>
                                            <th>ID</th>
                                            <th>Beneficiries</th>
                                            <th>Bank</th>
                                            <th>Account NO.</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Remark</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(payment, index) in payments.data">
                                            <td v-text="index+1"></td>
                                            <td v-text="payment.cohort.name"></td>
                                            <td v-text="payment.beneficiary.unique_id"></td>
                                            <td v-text="payment.beneficiary.name"></td>
                                            <td v-text="payment.beneficiary.bank_name"></td>
                                            <td v-text="payment.beneficiary.account_number"></td>
                                            <td >{{ payment.amount | formatMoney }}</td>
                                            <td v-html="getStatus(payment)"></td>
                                            <td v-text="payment.remark"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target=".payment-detail" @click="fetchPayment(payment)" v-tooltip="'View Payment'"><i class="feather icon-eye"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!payments.total"  module="general" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" v-if="!showCreatePanel" @click="showCreatePanel = !showCreatePanel"><i class="feather icon-plus"></i> {{trans('general.add_new')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterPaymentForm.page_length" :records="payments" @updateRecords="getPayments"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal fade payment-detail" tabindex="-1" role="dialog" aria-labelledby="paymentDetail" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="paymentDetail">Payment Details</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body" v-if="payment">
                            <h4>{{ payment.company_name }}
                                <span class="pull-right">{{payment.created_at | moment}}</span>
                            </h4>
                            <p>Transaction Reference: <strong>{{ payment.transaction_reference }}</strong></p>
                            <p>Reference: <strong>{{ payment.reference }}</strong></p>
                            <p>Amount: <strong>{{ payment.amount/100 }}</strong></p>
                            <p>Response Status: <strong>{{ payment.response_status }}</strong></p>
                            <p>Response Message: <strong>{{ payment.response_message }}</strong></p>
                            <!-- <div v-html="email_log.body"></div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">{{trans('general.close')}}</button>
                        </div>
                    </div>
                </div>
            </div>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'

    export default {
        components : { sortBy },
        data() {
            return {
                payments: {
                    total: 0,
                    data: []
                },
                payment: {},
                filterPaymentForm: {
                    type: '',
                    keyword: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
                orderByOptions: [
                    {
                        value: 'reference',
                        translation: 'Reference'
                    },
                    {
                        value: 'amount',
                        translation: 'Amount'
                    },
                    {
                        value: 'response_status',
                        translation: 'Response Status'
                    },
                    {
                        value: 'payment_type',
                        translation: 'Payment Type'
                    }
                ],
                showCreatePanel: false,
                showFilterPanel: false
            };
        },
        mounted(){
            if(!helper.hasPermission('list-payments')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('payment')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.filterPaymentForm.type = this.$route.query.type;

            this.getPayments();
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getPayments(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterPaymentForm);
                axios.get('/api/payments?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.payments = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            fetchPayment(payment){
                axios.get('/api/payments/'+payment.id)
                    .then(response => response.data)
                    .then(response => {
                        this.payment = response;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStatus(payment){
                return payment.status ? ('<span class="badge badge-success">Paid</span>') : ('<span class="badge badge-danger">Not paid</span>') ;
            },
        },
         filters: {
          moment(date) {
            return helper.formatDate(date);
          },

          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
        watch: {
            filterPaymentForm: {
                handler(val){
                    setTimeout(() => {
                        this.getPayments();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
