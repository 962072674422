<template>
    <form @submit.prevent="proceed" @keydown="termForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-lg-12 col-sm-6">
                <div class="form-group">
                    <label for="">Name</label>
                    <input class="form-control" :class="{ 'is-invalid': termForm.errors.has('name') }" type="text" value="" v-model="termForm.name" name="name" placeholder="Name">
                    <show-error :form-name="termForm" prop-name="name"></show-error>
                </div>
            </div>

        </div>
        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
            <span v-if="id">{{trans('general.update')}}</span>
            <span v-else>{{trans('general.save')}}</span>
        </button>
        <router-link to="/setting/term" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-show="id">{{trans('general.cancel')}}</router-link>
        <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" @click="$emit('cancel')">{{trans('general.cancel')}}</button>
    </form>
</template>


<script>
    export default {
        data() {
            return {
                termForm: new Form({
                    name: '',
                })
            };
        },
        props: ['id'],
        mounted() {
            if(this.id)
                this.getTerm();
        },
        methods: {
            proceed(){
                if(this.id)
                    this.updateTerm();
                else
                    this.storeTerm();
            },
            storeTerm(){
                this.termForm.post('/api/setting/term')
                    .then(response => {
                        toastr.success(response.message);
                        this.$emit('completed')
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getTerm(){
                axios.get('/api/setting/term/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.termForm.name = response.name;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/setting/term');
                    });
            },
            updateTerm(){
                this.termForm.patch('/api/setting/term/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/setting/term');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
