<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('general.logo')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Configuration</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{trans('general.logo')}}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/home')"><i class="feather icon-home"></i> {{trans('general.home')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            
                <section>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{trans('general.logo_type',{type:trans('general.sidebar')})}}</h4>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <upload-image id="sidebar_logo" upload-path="/configuration/logo/sidebar" remove-path="/configuration/logo/sidebar/remove" :image-source="logo.sidebar" @uploaded="updateSidebarLogo" @removed="updateSidebarLogo"></upload-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{trans('general.logo_type',{type:trans('general.main')})}}</h4>
                                </div>

                                <div class="card-content">
                                    <div class="card-body">
                                        <upload-image id="logo" upload-path="/configuration/logo/main" remove-path="/configuration/logo/main/remove" :image-source="logo.main" @uploaded="updateMainLogo" @removed="updateMainLogo"></upload-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        </div>

        
    </div>
</template>

<script>
    import uploadImage from '../../../components/upload-image'

    export default {
        components : { uploadImage },
        data() {
            return {
                logo: {
                    main: '',
                    sidebar: ''
                }
            }
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            this.logo.main = helper.getConfig('main_logo');
            this.logo.sidebar = helper.getConfig('sidebar_logo');
        },
        methods: {
            updateMainLogo(val){
                this.$store.dispatch('setConfig',{
                    main_logo: val
                });
            },
            updateSidebarLogo(val){
                this.$store.dispatch('setConfig',{
                    sidebar_logo: val
                });
            }
        }
    }
</script>
