<template>
    <form @submit.prevent="proceed" @keydown="paymentScheduleForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label for="">Cohort</label>
                    <select class="form-control" :class="{ 'is-invalid': paymentScheduleForm.errors.has('cohort_id') }" name="cohort_id" v-model="paymentScheduleForm.cohort_id">
                        <option value="">Cohort</option>
                        <option v-for="cohort in cohorts" :value="cohort.id">{{cohort.name}}</option>
                    </select>
                    <show-error :form-name="paymentScheduleForm" prop-name="cohort_id"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label for="">Cycle</label>
                    <select class="form-control" :class="{ 'is-invalid': paymentScheduleForm.errors.has('cycle_id') }" name="cycle_id" v-model="paymentScheduleForm.cycle_id">
                        <option value="">Cycle</option>
                        <option v-for="cycle in cycles" :value="cycle.id">{{cycle.name}}</option>
                    </select>
                    <show-error :form-name="paymentScheduleForm" prop-name="cycle_id"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label for="">Tranch</label>
                    <select class="form-control" :class="{ 'is-invalid': paymentScheduleForm.errors.has('trench_id') }" name="trench_id" v-model="paymentScheduleForm.trench_id">
                        <option value="">Tranch</option>
                        <option v-for="trench in trenches" :value="trench.id">{{trench.name}}</option>
                    </select>
                    <show-error :form-name="paymentScheduleForm" prop-name="trench_id"></show-error>
                </div>
            </div>
            

        </div>
        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
            <span v-if="id">{{trans('general.update')}}</span>
            <span v-else>{{trans('general.save')}}</span>
        </button>
        <router-link to="/setting/payment-schedule" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-show="id">{{trans('general.cancel')}}</router-link>
        <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" @click="$emit('cancel')">{{trans('general.cancel')}}</button>
    </form>
</template>


<script>
    import datepicker from 'vuejs-datepicker'
    export default {
        components: {datepicker},
        data() {
            return {
                paymentScheduleForm: new Form({
                    cycle_id: '',
                    cohort_id: '',
                    trench_id: '',
                }),
                cohorts: [],
                cycles: [],
                trenches: []
            };
        },
        props: ['id'],
        mounted() {
            this.fetchPreRequisites();
            if(this.id)
                this.getPaymentSchedule();
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cycles = response.cycles;
                        this.cohorts = response.cohorts;
                        this.trenches = response.trenches;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updatePaymentSchedule();
                else
                    this.storePaymentSchedule();
            },
            storePaymentSchedule(){
                this.paymentScheduleForm.post('/api/setting/payment-schedule')
                    .then(response => {
                        toastr.success(response.message);
                        this.$emit('completed')
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getPaymentSchedule(){
                axios.get('/api/setting/payment-schedule/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.paymentScheduleForm.cohort_id = response.cohort_id;
                        this.paymentScheduleForm.cycle_id = response.cycle_id;
                        this.paymentScheduleForm.trench_id = response.trench_id;
                        this.paymentScheduleForm.amount = response.amount;
                    
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/setting/payment-schedule');
                    });
            },
            updatePaymentSchedule(){
                this.paymentScheduleForm.patch('/api/setting/payment-schedule/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/setting/payment-schedule');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
