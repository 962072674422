<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('attendance.attendance')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('attendance.attendance') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push('attendances/create')"><i class="feather icon-plus"></i> {{trans('attendance.add_new_attendance')}}</button>
                    
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="feather icon-filter"></i> {{trans('general.filter')}}</button>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('general.keyword')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterAttendanceForm.keyword">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <date-range-picker :start-date.sync="filterAttendanceForm.start_date" :end-date.sync="filterAttendanceForm.end_date" :label="trans('general.date_between')"></date-range-picker>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="attendances.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterAttendanceForm.sort_by" :order="filterAttendanceForm.order" @updateSortBy="value => {filterAttendanceForm.sort_by = value}"  @updateOrder="value => {filterAttendanceForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="attendances.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>{{trans('beneficiary.admission_number')}}</th>
                                            <th>{{trans('beneficiary.name')}}</th>
                                            <th>{{trans('beneficiary.school')}}</th>
                                            <th>{{trans('beneficiary.class')}}</th>
                                            <th>{{trans('attendance.cycle')}}</th>
                                            <th>{{trans('attendance.term')}}</th>
                                            <th>{{trans('attendance.week')}}</th>
                                            <th>{{trans('attendance.reason')}}</th>
                                            <th>{{trans('attendance.remark')}}</th>
                                            <th>{{trans('attendance.percentage')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(attendance, index) in attendances.data">
                                            <td v-text="index+1"></td>
                                            <td v-text="attendance.beneficiary.admission_number"></td>
                                            <td v-text="attendance.beneficiary.name"></td>
                                            <td v-text="attendance.school.name"></td>
                                            <td v-text="attendance.beneficiary.class"></td>
                                            <td v-text="attendance.cycle.name"></td>
                                            <td v-text="attendance.term.name"></td>
                                            <td v-text="attendance.week"></td>
                                            <td v-text="attendance.reason"></td>
                                            <td v-text="attendance.remark"></td>
                                            <td>{{ attendance.cumulative_percentage }}%</td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    
                                                    <router-link :to="`attendances/${attendance.id}`" class="btn btn-success btn-sm" v-tooltip="trans('attendance.view_attendance')"><i class="feather icon-eye"></i></router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!attendances.total" module="attendance" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" @click="$router.push('attendances/create')"><i class="feather icon-plus"></i> {{trans('attendance.add_new_attendance')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterAttendanceForm.page_length" :records="attendances" @updateRecords="getAttendances"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'
    import dateRangePicker from '../../components/date-range-picker'

    export default {
        components : { sortBy,dateRangePicker },
        data() {
            return {
                userRole: '',
                attendances: {
                    total: 0,
                    data: []
                },
                filterAttendanceForm: {
                    type: '',
                    keyword: '',
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
                orderByOptions: [
                    {
                        value: 'admission_number',
                        translation: i18n.beneficiary.admission_number
                    },
                    {
                        value: 'name',
                        translation: i18n.beneficiary.name
                    },
                    {
                        value: 'email',
                        translation: i18n.beneficiary.email
                    }
                ],
                showFilterPanel: false,
            };
        },
        mounted(){
            if(!helper.hasPermission('view-attendance')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('attendance')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.filterAttendanceForm.type = this.$route.query.type;

            this.getAttendances();
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getAttendances(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterAttendanceForm);
                axios.get('/api/attendances?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.attendances = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            }
        },
        watch: {
            filterAttendanceForm: {
                handler(val){
                    setTimeout(() => {
                        this.getAttendances();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
