<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('locale.locale')}}
                            <span class="card-subtitle" v-if="locales">{{trans('general.total_result_found',{'count' : locales.total})}}</span>
                            <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Configuration</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('locale.locale')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-settings"></i></button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#">
                            <button class="btn btn-primary btn-sm pull-right m-r-10" v-if="locales.total && !showCreatePanel" @click="showCreatePanel = !showCreatePanel"><i class="fas fa-globe"></i> {{trans('locale.add_new_locale')}}</button>
                        </a>
                        <a class="dropdown-item" href="#">
                            <button class="btn btn-primary btn-sm pull-right m-r-10" v-if="!showWordPanel" @click="showWordPanel = !showWordPanel"><i class="feather icon-plus"></i> {{trans('locale.add_new_word')}}</button>
                        </a>
                    </div>
                </div>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="card border-bottom" v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('locale.add_new_locale')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <show-tip module="locale" tip="tip_locale"></show-tip>
                                    <div class="row">
                                    <div class="col-12 col-sm-12">
                                        <locale-form @completed="getLocales" @cancel="showCreatePanel = !showCreatePanel"></locale-form>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </transition>

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showWordPanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('locale.add_new_word')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <show-tip module="locale" tip="tip_add_word" type="field"></show-tip>
                                <form @submit.prevent="addWord" @keydown="addWordForm.errors.clear($event.target.name)">
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">{{trans('locale.word')}}</label>
                                                <input class="form-control" :class="{ 'is-invalid': addWordForm.errors.has('word') }" type="text" value="" v-model="addWordForm.word" name="word" :placeholder="trans('locale.word')">
                                                <show-error :form-name="addWordForm" prop-name="word"></show-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">{{trans('locale.module')}}</label>
                                                <select v-model="addWordForm.module" class="custom-select col-12" :class="{ 'is-invalid': addWordForm.errors.has('module') }" @change="addWordForm.errors.clear('module')">
                                                  <option value="">{{trans('general.select_one')}}</option>
                                                  <option v-for="module in modules" v-bind:value="module">
                                                    {{ toWord(module) }}
                                                  </option>
                                                </select>
                                                <show-error :form-name="addWordForm" prop-name="module"></show-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">{{trans('locale.translation')}}</label>
                                                <input class="form-control" :class="{ 'is-invalid': addWordForm.errors.has('translation') }" type="text" value="" v-model="addWordForm.translation" name="translation" :placeholder="trans('locale.translation')">
                                                <show-error :form-name="addWordForm" prop-name="translation"></show-error>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.add')}}</button>
                                    <button type="button" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" @click="showWordPanel = !showWordPanel">{{trans('general.cancel')}}</button>
                                </form>
                            </div>
                            </div>
                        </div>
                    </transition>

                    
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="locales.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.name')}}</th>
                                            <th>{{trans('locale.locale')}}</th>
                                            <th>{{trans('general.created_at')}}</th>
                                            <th>{{trans('general.updated_at')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="locale in locales.data">
                                            <td v-text="locale.name"></td>
                                            <td v-text="locale.locale"></td>
                                            <td>{{locale.created_at | momentDateTime}}</td>
                                            <td>{{locale.updated_at | momentDateTime}}</td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-info btn-sm" v-tooltip="trans('locale.edit_locale')" @click.prevent="editLocale(locale)"><i class="feather icon-edit"></i></button>
                                                    <router-link :to="`/configuration/locale/${locale.locale}/auth`" class="btn btn-success btn-sm" v-tooltip="trans('locale.translation')"><i class="fas fa-arrow-circle-right"></i></router-link>
                                                    <button class="btn btn-danger btn-sm" :key="locale.id" v-if="locale.locale !== 'en'" v-confirm="{ok: confirmDelete(locale)}" v-tooltip="trans('locale.delete_locale')"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!locales.total" module="locale" title="module_info_title" description="module_info_description" icon="globe"></module-info>
                            <pagination-record :page-length.sync="filterLocaleForm.page_length" :records="locales" @updateRecords="getLocales" @change.native="getLocales"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import localeForm from './form'

    export default {
        components : { localeForm },
        data() {
            return {
                locales: {
                    total: 0,
                    data: []
                },
                filterLocaleForm: {
                    page_length: helper.getConfig('page_length')
                },
                addWordForm: new Form({
                    word: '',
                    translation: '',
                    module: ''
                }),
                showCreatePanel: false,
                showWordPanel: false,
                modules: []
            };
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('multilingual')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getLocales();
        },
        methods: {
            getLocales(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterLocaleForm);
                axios.get('/api/locale?page=' + page + url)
                    .then(response => response.data)
                    .then(response => {
                        this.locales = response.locales;
                        this.modules = response.modules;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            editLocale(locale){
                this.$router.push('/configuration/locale/'+locale.id+'/edit');
            },
            confirmDelete(locale){
                return dialog => this.deleteLocale(locale);
            },
            deleteLocale(locale){
                axios.delete('/api/locale/'+locale.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getLocales();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            addWord(){
                this.addWordForm.post('/api/locale/add-word')
                    .then(response => {
                        toastr.success(response.message)
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            toWord(word){
                return helper.toWord(word);
            }
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          }
        }
    }
</script>
