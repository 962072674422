<template>
    <div class="col-lg-4 col-12" v-if="user">
        <div class="card">
          <div class="card-header">
            <h4>About</h4>
            <i class="feather icon-more-horizontal cursor-pointer"></i>
          </div>
          <div class="card-body">
            <center class="m-t-30"> <img :src="getAvatar(user)" class="rounded-circle img-border box-shadow-1" width="150" />
                    <h4 class="card-title m-t-10">{{user.profile.first_name+' '+user.profile.last_name}}
                        <span v-if="user.profile.gender === 'male'"><i class="fas fa-male fa-2x"></i></span>
                        <span v-if="user.profile.gender === 'female'"><i class="fas fa-female fa-2x"></i></span>
                    </h4>
                    <div><span v-for="status in getUserStatus(user)" :class="['badge','badge-'+status.color,'m-r-5']">{{status.label}}</span></div>
                    </br>
                    <div class="row m-t-10" v-if="user.id != getAuthUser('id')">
                        <div class="col-12" v-if="user.status == 'activated'">
                            <button type="button" class="btn btn-danger btn-sm" @click="updateStatus('banned')"><i class="fas fa-ban"></i> {{trans('user.user_action_ban')}}</button>
                        </div>
                        <div class="col-12" v-if="user.status == 'disapproved'">
                            <button type="button" class="btn btn-success btn-sm" @click="updateStatus('activated')"><i class="fas fa-check"></i> {{trans('user.user_action_approve')}}</button>
                        </div>
                        <div class="col-6" v-if="user.status == 'pending_activation' || user.status == 'pending_approval'">
                            <button type="button" class="btn btn-success btn-sm" @click="updateStatus('activated')"><i class="fas fa-user-plus"></i> {{trans('user.user_action_approve')}}</button>
                        </div>
                        <div class="col-6" v-if="user.status == 'pending_activation' || user.status == 'pending_approval'">
                            <button type="button" class="btn btn-danger btn-sm" @click="updateStatus('disapproved')"><i class="fas fa-user-times"></i> {{trans('user.user_action_disapprove')}}</button>
                        </div>
                        <div class="col-12" v-if="user.status == 'banned'">
                            <button type="button" class="btn btn-success btn-sm" @click="updateStatus('activated')"><i class="fas fa-check"></i> {{trans('user.user_action_activate')}}</button>
                        </div>
                    </div>
                </center>
            <div class="mt-1">
              <h6 class="mb-0">{{trans('user.date_of_birth')}}:</h6>
              <p>{{user.profile.date_of_birth | moment}}</p>
            </div>
            <div class="mt-1">
                <h6 class="mb-0">{{trans('user.date_of_anniversary')}}:</h6>
                <p>{{user.profile.date_of_anniversary | moment}}</p>
            </div>
            <div class="mt-1">
              <h6 class="mb-0">{{trans('user.email')}}:</h6>
              <p>{{user.email}}</p>
            </div>
            <div class="mt-1" v-if="user.profile.phone">
              <h6 class="mb-0">{{trans('user.phone')}}:</h6>
              <p v-if="user.profile.phone">{{user.profile.phone}}</p>
            </div>
            <div class="mt-1">
              <h6 class="mb-0">{{trans('user.created_at')}}:</h6>
              <p>{{user.created_at | momentDateTime}}</p>
            </div>
            <div class="mt-1">
              <h6 class="mb-0">{{trans('user.updated_at')}}:</h6>
              <p>{{user.updated_at | momentDateTime}}</p>
            </div>
            <div class="mt-1">
                <h6 class="mb-0">{{trans('user.social_profile')}}</h6>
                <button type="button" v-if="user.profile.facebook_profile" :href="user.profile.facebook_profile" class="btn btn-sm btn-icon btn-primary mr-25 p-25"><i class="feather icon-facebook"></i></button>
                <button type="button" v-if="user.profile.twitter_profile" :href="user.profile.twitter_profile" class="btn btn-circle btn-primary mr-25 p-25"><i class="feather icon-twitter"></i></button>
                <button type="button" v-if="user.profile.linkedin_profile" :href="user.profile.linkedin_profile" class="btn btn-circle btn-primary mr-25 p-25"><i class="feather icon-instagram"></i></button>
                <button type="button" v-if="user.profile.google_plus_profile" :href="user.profile.google_plus_profile" class="btn btn-circle btn-primary mr-25 p-25"><i class="feather icon-globe"></i></button>
              
            </div>
          </div>
        </div>
        
      </div>

</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            user: {
                default: {
                    profile: {}
                },
                required: true
            }
        },
        methods: {
            getUserStatus(user){
                return helper.getUserStatus(user);
            },
            updateStatus(status){
                axios.post('/api/user/'+this.user.id+'/status',{
                    status: status
                })
                .then(response => response.data)
                .then(response => {
                    this.user.status = status;
                    toastr.success(response.message);
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            getAvatar(user){
                return  helper.getAvatar(user);
            }
        },
        mounted(){
        },
        computed: {
        },
        watch: {
            user(newVal){
                this.user = newVal;
            }
        },
        filters: {
          moment(date) {
            return helper.formatDate(date);
          },
          momentDateTime(date) {
            return helper.formatDateTime(date);
          }
        }
    }
</script>
