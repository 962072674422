var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.ipFilterForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("ip_filter.start_ip"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ipFilterForm.start_ip,
                    expression: "ipFilterForm.start_ip",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.ipFilterForm.errors.has("start_ip"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "start_ip",
                  placeholder: _vm.trans("ip_filter.start_ip"),
                },
                domProps: { value: _vm.ipFilterForm.start_ip },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.ipFilterForm, "start_ip", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.ipFilterForm,
                  "prop-name": "start_ip",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("ip_filter.end_ip"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ipFilterForm.end_ip,
                    expression: "ipFilterForm.end_ip",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.ipFilterForm.errors.has("end_ip") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "end_ip",
                  placeholder: _vm.trans("ip_filter.end_ip"),
                },
                domProps: { value: _vm.ipFilterForm.end_ip },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.ipFilterForm, "end_ip", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.ipFilterForm, "prop-name": "end_ip" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("ip_filter.description"))),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ipFilterForm.description,
                    expression: "ipFilterForm.description",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.ipFilterForm.errors.has("description"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  rows: "1",
                  name: "description",
                  placeholder: _vm.trans("ip_filter.description"),
                },
                domProps: { value: _vm.ipFilterForm.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.ipFilterForm,
                      "description",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.ipFilterForm,
                  "prop-name": "description",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { type: "submit" },
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.id,
              expression: "id",
            },
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { to: "/configuration/ip-filter" },
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }