<template>
    <form @submit.prevent="proceed" @keydown="eventForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">{{trans('calendar.title')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': eventForm.errors.has('title') }" type="text" value="" v-model="eventForm.title" name="title" :placeholder="trans('calendar.title')">
                    <show-error :form-name="eventForm" prop-name="title"></show-error>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">{{trans('calendar.description')}}</label>
                    <input v-model="eventForm.description" rows="1" name="description"  class="form-control" :class="{ 'is-invalid': eventForm.errors.has('description') }" :placeholder="trans('calendar.description')">
                    <show-error :form-name="eventForm" prop-name="description"></show-error>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">{{trans('calendar.start')}}</label>
                    <datepicker v-model="eventForm.start" :bootstrapStyling="true" @selected="eventForm.errors.clear('start')" :class="{ 'form-control is-invalid': eventForm.errors.has('start') }" :placeholder="trans('calendar.start')"></datepicker>
                    <show-error :form-name="eventForm" prop-name="start"></show-error>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="">{{trans('calendar.end')}}</label>
                    <datepicker v-model="eventForm.end" :bootstrapStyling="true" @selected="eventForm.errors.clear('end')"  :class="{ 'form-control is-invalid': eventForm.errors.has('end') }" :placeholder="trans('calendar.end')"></datepicker>
                    <show-error :form-name="eventForm" prop-name="end"></show-error>
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
            <span v-if="id">{{trans('general.update')}}</span>
            <span v-else>{{trans('general.save')}}</span>
        </button>
        <router-link to="/todo" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-show="id">{{trans('general.cancel')}}</router-link>
        <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" @click="$emit('cancel')">{{trans('general.cancel')}}</button>
    </form>
</template>


<script>
    import datepicker from 'vuejs-datepicker'
    import autosizeTextarea from '../../components/autosize-textarea'

    export default {
        components: {datepicker,autosizeTextarea},
        data() {
            return {
                eventForm: new Form({
                    title : '',
                    description : '',
                    start: '',
                    end: ''
                })
            };
        },
        props: ['id'],
        mounted() {
            if(this.id)
                this.getEvent();
        },
        methods: {
            proceed(){
                if(this.id)
                    this.updateEvent();
                else
                    this.storeEvent();
            },
            storeEvent(){
                this.eventForm.start = moment(this.eventForm.start).format('YYYY-MM-DD');
                this.eventForm.end = moment(this.eventForm.end).format('YYYY-MM-DD');
                this.eventForm.post('/api/calendar')
                    .then(response => {
                        toastr.success(response.message);
                        this.$emit('completed')
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getEvent(){
                axios.get('/api/calendar')
                    .then(response => response.data)
                    .then(response => {
                        this.eventForm.title = response.title;
                        this.eventForm.description = response.description;
                        this.eventForm.start = response.start;
                        this.eventForm.end = response.end;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/calendar');
                    });
            },
            updateEvent(){
                this.eventForm.start = moment(this.eventForm.start).format('YYYY-MM-DD');
                this.eventForm.end = moment(this.eventForm.end).format('YYYY-MM-DD');
                this.eventForm.patch('/api/calendar/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/calendar');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
