<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('user.user')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">User</a>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/user')"><i class="feather icon-list"></i> {{trans('user.user_list')}}</button>
                </div>
            </div>
        </div>

    <div class="content-body">
        <div id="user-profile">
            <user-sidebar menu="social" :id="id"></user-sidebar>
  
  <section id="profile-info">
    <div class="row">
        <user-summary :user="user"></user-summary>
      
      <div class="col-lg-8 col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{trans('user.social')}}</h4>
            </div>
          <div class="card-content">
              <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-1">
                <form @submit.prevent="submit" @keydown="userForm.errors.clear($event.target.name)">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.sm_profile',{name: 'Facebook'})}}</label>
                                <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('facebook_profile') }" type="text" value="" v-model="userForm.facebook_profile" name="facebook_profile" :placeholder="trans('user.sm_profile',{name: 'Facebook'})">
                                <show-error :form-name="userForm" prop-name="facebook_profile"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.sm_profile',{name: 'Twitter'})}}</label>
                                <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('twitter_profile') }" type="text" value="" v-model="userForm.twitter_profile" name="twitter_profile" :placeholder="trans('user.sm_profile',{name: 'Twitter'})">
                                <show-error :form-name="userForm" prop-name="twitter_profile"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.sm_profile',{name: 'LinkedIn'})}}</label>
                                <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('linkedin_profile') }" type="text" value="" v-model="userForm.linkedin_profile" name="linkedin_profile" :placeholder="trans('user.sm_profile',{name: 'LinkedIn'})">
                                <show-error :form-name="userForm" prop-name="linkedin_profile"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.sm_profile',{name: 'Google Plus'})}}</label>
                                <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('google_plus_profile') }" type="text" value="" v-model="userForm.google_plus_profile" name="google_plus_profile" :placeholder="trans('user.sm_profile',{name: 'Google Plus'})">
                                <show-error :form-name="userForm" prop-name="google_plus_profile"></show-error>
                            </div>
                        </div>
                    </div>
                                        
                    <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">{{trans('general.save')}}</button>
                </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
   
   </div>
    </div>
</template>

<script>
    import userSidebar from './user-sidebar'
    import userSummary from './summary'

    export default {
        components : { userSidebar,userSummary },
        data() {
            return {
                id:this.$route.params.id,
                userForm: new Form({
                    facebook_profile: '',
                    twitter_profile: '',
                    linkedin_profile: '',
                    google_plus_profile: ''
                },false),
                user: ''
            };
        },
        mounted(){
            if(!helper.hasPermission('edit-user')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            axios.get('/api/user/'+this.id)
                .then(response => response.data)
                .then(response => {
                    this.user = response.user;
                    this.userForm.facebook_profile = response.user.profile.facebook_profile;
                    this.userForm.twitter_profile = response.user.profile.twitter_profile;
                    this.userForm.linkedin_profile = response.user.profile.linkedin_profile;
                    this.userForm.google_plus_profile = response.user.profile.google_plus_profile;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                    this.$router.push('/user');
                })
        },
        methods: {
            submit(){
                this.userForm.patch('/api/user/'+this.id+'/social')
                    .then(response => {
                        toastr.success(response.message);
                        this.user.profile.facebook_profile = this.userForm.facebook_profile;
                        this.user.profile.twitter_profile = this.userForm.twitter_profile;
                        this.user.profile.linkedin_profile = this.userForm.linkedin_profile;
                        this.user.profile.google_plus_profile = this.userForm.google_plus_profile;
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
