<template>
    <div>
        <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">{{ title }} Beneficiaries Graph</h2>
                    <div class="breadcrumb-wrapper col-12">
                        
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/home">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    Statistic
                                </li>
                                <li class="breadcrumb-item active">
                                    Beneficiary
                                </li>
                            </ol> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">   
            <div class="form-group breadcrum-right">
                <button v-if="type == 'selected'" class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push(`/beneficiaries`)"><i class="feather icon-list"></i> List View</button>
                <button v-if="type == 'enrolled'" class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push(`/beneficiaries/enrolled-list`)"><i class="feather icon-list"></i> List View</button>
                <button v-if="type == 'validated'" class="btn btn-primary btn-sm pull-right ml-1"  @click="$router.push(`/beneficiaries/validated-list`)"><i class="feather icon-list"></i> List View</button>
            </div>
        </div>
    </div>

    <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="card border-bottom">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">Cohort</label>
                                            <select class="form-control" name="cohort_id" v-model="cohort_id" @change="onChange($event)">
                                                <option value="all">All Cohort</option>
                                                <option v-for="cohort in cohorts" :value="cohort.id">{{cohort.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
          <div class="row">
              <div class="col-lg-12 col-md-6 col-12">
                  <div class="card">
                      <div class="card-header d-flex justify-content-between align-items-end">
                          <h4 class="card-title" v-if="type == 'selected'">Selected Beneficiaries</h4>
                          <h4 class="card-title" v-if="type == 'enrolled'">Enrolled Beneficiaries</h4>
                          <h4 class="card-title" v-if="type == 'validated'">Validated Beneficiaries</h4>
                          <p class="font-medium-5 mb-0"><i class="feather icon-settings text-muted cursor-pointer"></i></p>
                      </div>
                      <div class="card-content">
                          <div class="card-body pb-0">
                              <beneficiaryChart :data="getChartData" :label="getChartLabel" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
        </section>
   
   </div>
    </div>
</template>

<script>
import beneficiaryChart from '../../components/beneficiary-chart'
    export default {
        components: { beneficiaryChart },
        data() {
            return {
                cohort_id: this.$route.query.cohort,
                type: this.$route.query.type,
                title: '',
                cohorts: [],
                chartData: [],
                chartLabel: []
            }
        },
        mounted(){
            this.fetchPreRequisites();
            axios.get('/api/statistic/beneficiaries/?type= ' + this.$route.query.type + '&cohort=' + this.$route.query.cohort)
                .then(response => response.data)
                .then(response => {
                    this.title = response.title[0];
                    this.chartLabel = response.labels;
                    this.chartData = response.data;
                    if (response.data <= 0) {
                        toastr.error('No beneficiaries found for this cohort');
                    }
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })

        },
        computed: {
          getChartData() {
            return this.chartData
          },
          getChartLabel() {
            return this.chartLabel
          }
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cohorts = response.cohorts;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            onChange(event) {
                this.cohort_id = event.target.value;
                window.location.href = '/statistic/beneficiaries?type=' + this.type + '&cohort=' + this.cohort_id;
            }
        }
       
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>