var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.schoolForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.schoolForm.name,
                    expression: "schoolForm.name",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.schoolForm.errors.has("name") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "name",
                  placeholder: "Name",
                },
                domProps: { value: _vm.schoolForm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.schoolForm, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.schoolForm, "prop-name": "name" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Longitude")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.schoolForm.longitude,
                    expression: "schoolForm.longitude",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.schoolForm.errors.has("longitude") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "longitude",
                  placeholder: "Longitude",
                },
                domProps: { value: _vm.schoolForm.longitude },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.schoolForm, "longitude", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.schoolForm,
                  "prop-name": "longitude",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Latitude")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.schoolForm.latitude,
                    expression: "schoolForm.latitude",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.schoolForm.errors.has("latitude") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "latitude",
                  placeholder: "Latitude",
                },
                domProps: { value: _vm.schoolForm.latitude },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.schoolForm, "latitude", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.schoolForm, "prop-name": "latitude" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Altitude")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.schoolForm.altitude,
                    expression: "schoolForm.altitude",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.schoolForm.errors.has("altitude") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "altitude",
                  placeholder: "Altitude",
                },
                domProps: { value: _vm.schoolForm.altitude },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.schoolForm, "altitude", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.schoolForm, "prop-name": "altitude" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Accuracy")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.schoolForm.accuracy,
                    expression: "schoolForm.accuracy",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.schoolForm.errors.has("accuracy") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "accuracy",
                  placeholder: "Accuracy",
                },
                domProps: { value: _vm.schoolForm.accuracy },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.schoolForm, "accuracy", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.schoolForm, "prop-name": "accuracy" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("LGA")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.schoolForm.lga_id,
                      expression: "schoolForm.lga_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.schoolForm.errors.has("lga_id") },
                  attrs: { name: "lga_id" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.schoolForm,
                        "lga_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("LGA")]),
                  _vm._v(" "),
                  _vm._l(_vm.lgas, function (lga) {
                    return _c("option", { domProps: { value: lga.id } }, [
                      _vm._v(_vm._s(lga.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.schoolForm, "prop-name": "lga_id" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
          attrs: { type: "submit" },
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { to: "/schools" },
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }