<template>
        <section class="row flexbox-container">
  <div class="col-xl-8 col-11 d-flex justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-6 d-lg-block d-none text-center align-self-center">
                  <img src="/images/pages/login.jpeg" width="332px" alt="branding logo">
              </div>
              <div class="col-lg-6 col-12 p-0">
                  <div class="card rounded-0 mb-0 px-2 py-1">
                      <div class="card-header pb-1">
                          <div class="card-title">
                              <h4 class="mb-0">{{trans('auth.password')}}</h4>
                          </div>
                      </div>
                      
                      <div class="card-content">
                          <div class="card-body">
                                <center class="m-t-30"> <img :src="getAuthUser('avatar')" class="rounded-circle img-border box-shadow-1" width="100" />
                                    <h4 class="card-title m-t-10">{{getAuthUser('full_name')}}</h4>
                                </center><br>
                              <form id="lockScreenForm" @submit.prevent="submit" @keydown="lockScreenForm.errors.clear($event.target.name)">
                                <fieldset class="form-label-group form-group position-relative has-icon-left">

                                  <input type="password" name="password" class="form-control text-center" :class="{ 'is-invalid': lockScreenForm.errors.has('password') }" :placeholder="trans('auth.password')" v-model="lockScreenForm.password" autocomplete="password" autofocus>
                        
                                <div class="form-control-position">
                                      <i class="feather icon-lock"></i>
                                  </div>
                                      <label for="password">{{ trans('auth.password') }}</label>

                                      <show-error :form-name="lockScreenForm" prop-name="password"></show-error>
 
                              </fieldset>
                                 

                                  <div class="float-md-left d-block mb-1">
                                    <a href="#" @click.prevent="logout" class="btn btn-outline-primary btn-block px-75"><i class="fas fa-power-off"></i> {{trans('auth.logout')}}</a>
                                  </div>
                                  <div class="float-md-right d-block mb-1">
                                    <button type="submit" class="btn btn-primary btn-block px-75">{{trans('auth.unlock')}}</button>
                                  </div>
                              </form>

                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    
</template>
<script>
    import guestFooter from '../../layouts/guest-footer.vue'

    export default {
        components: {guestFooter},
        data(){
            return {
                lockScreenForm: new Form({
                    password: '',
                })
            }
        },
        mounted(){
            if(!helper.getConfig('lock_screen') || !helper.isScreenLocked())
                this.$router.push('/home');

            if(!helper.getConfig('mode'))
                this.lockScreenForm.password = 'abcd1234';
        },
        methods: {
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            submit(){
                this.lockScreenForm.post('/api/auth/lock')
                    .then(response => {
                        this.$store.dispatch('setLastActivity');
                        toastr.success(response.message);
                        this.$router.push('/home');
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            }
        },
        computed: {
        }
    }
</script>
