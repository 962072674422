<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.cohort')}} Validated
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/beneficiaries">
                                        {{ trans('beneficiary.beneficiary') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('beneficiary.cohort') }} Validated
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="feather icon-filter"></i> {{trans('general.filter')}}</button>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('general.keyword')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterBeneficiaryForm.keyword">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <date-range-picker :start-date.sync="filterBeneficiaryForm.start_date" :end-date.sync="filterBeneficiaryForm.end_date" :label="trans('general.date_between')"></date-range-picker>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="beneficiaries.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterBeneficiaryForm.sort_by" :order="filterBeneficiaryForm.order" @updateSortBy="value => {filterBeneficiaryForm.sort_by = value}"  @updateOrder="value => {filterBeneficiaryForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="beneficiaries.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>{{trans('beneficiary.cohort')}}</th>
                                            <th>{{trans('general.status')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(beneficiary, index) in beneficiaries.data">
                                            <td v-text="index+1"></td>
                                            <td v-text="beneficiary.cohort.name"></td>
                                            <td v-html="getStateCctStatus(beneficiary)"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    
                                                    <a :href="`/beneficiaries/download/${beneficiary.cohort_id}/${beneficiary.state_id}?type=validation`" class="btn btn-success btn-sm" v-tooltip="trans('beneficiary.download_beneficiaries')" v-if="hasPermission('download-beneficiaries')"><i class="feather icon-download"></i></a>

                                                    <button class="btn btn-info btn-sm" v-tooltip="trans('beneficiary.send_to_state_cct')" @click.prevent="sendToStateCct(beneficiary)" v-if="hasPermission('send-to-state-cct')"><i class="feather icon-send"></i></button>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!beneficiaries.total" module="beneficiary" title="module_info_title" description="module_info_description" icon="check-circle">
                                
                            </module-info>
                            <pagination-record :page-length.sync="filterBeneficiaryForm.page_length" :records="beneficiaries" @updateRecords="getBeneficiaries"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'
    import dateRangePicker from '../../components/date-range-picker'

    export default {
        components : { sortBy,dateRangePicker },
        data() {
            return {
                userRole: '',
                beneficiaries: {
                    total: 0,
                    data: []
                },
                filterBeneficiaryForm: {
                    keyword: '',
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
                orderByOptions: [
                    {
                        value: 'name',
                        translation: i18n.beneficiary.name
                    },
                    {
                        value: 'email',
                        translation: i18n.beneficiary.email
                    }
                ],
                showFilterPanel: false,
            };
        },
        mounted(){
            if(!helper.hasPermission('view-enrolled-list')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getBeneficiaries();
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getBeneficiaries(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterBeneficiaryForm);
                axios.get('/api/beneficiaries/validated?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.beneficiaries = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            
            getStateCctStatus(beneficiary){
                if(beneficiary.cohort.movement == null){
                    return '<span class="badge badge-warning">'+i18n.beneficiary.pending_sent_to_socu+'</span>';
                } else {
                    return beneficiary.cohort.movement[0].send_to_state_cct ? ('<span class="badge badge-success">'+i18n.beneficiary.send_to_state_cct+'</span>') : ('<span class="badge badge-warning">'+i18n.beneficiary.not_send_to_state_cct+'</span>') ;
                }
                
            },

            sendToStateCct(beneficiary){
                axios.post('/api/beneficiaries/'+beneficiary.cohort_id+'/send-to-state-cct')
                    .then(response => response.data)
                    .then(response => {
                        this.getBeneficiaries();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            }
        },
        watch: {
            filterBeneficiaryForm: {
                handler(val){
                    setTimeout(() => {
                        this.getBeneficiaries();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
