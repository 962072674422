<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('message.compose')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Message</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('message.compose')}}</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/home')"><i class="feather icon-home"></i> {{trans('general.home')}}</button>
                </div>
            </div>
        </div>

        <div class="content-area-wrapper">
            <message-sidebar menu="compose" :statistics="statistics"></message-sidebar>

            <div class="app-content-overlay"></div>
            
            <div class="content-right">
                <div class="content-wrapper">
                    <div class="content-header row"></div>
                    <div class="content-body">
                        <div class="app-content-overlay"></div>
                        <div class="email-app-area">
                            <div class="email-app-list-wrapper">
                                <div class="email-app-list">
                                    <div class="app-fixed-search">
                                        <div class="sidebar-toggle d-block d-lg-none"><i class="feather icon-menu"></i></div>
                  <fieldset class="form-group position-relative has-icon-left m-0">
                      <input type="text" class="form-control" id="email-search" placeholder="Search email">
                      <div class="form-control-position">
                          <i class="feather icon-search"></i>
                      </div>
                  </fieldset>
                                    </div>

                                    <div class="app-action">
                                        <div class="action-left">
                                            
                                        </div>
                                        <div class="action-right">
                                            <message-form></message-form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>


<script>
    import messageForm from './form';
    import messageSidebar from './message-sidebar'

    export default {
        components : { messageForm,messageSidebar },
        data() {
            return {
                statistics: {
                    sent: 0,
                    inbox: 0,
                    draft: 0,
                    trash: 0
                }
            };
        },
        mounted(){
            if(!helper.featureAvailable('message')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
            
            if(!helper.hasPermission('access-message')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            axios.post('/api/message/statistics')
                .then(response => response.data)
                .then(response => {
                    this.statistics.inbox = response.inbox;
                    this.statistics.sent = response.sent;
                    this.statistics.draft = response.draft;
                    this.statistics.trash = response.trash;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
        }
    }
</script>
