var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      { staticClass: "col-xl-8 col-11 d-flex justify-content-center" },
      [
        _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6 col-12 p-0" }, [
              _c("div", { staticClass: "card rounded-0 mb-0 px-2" }, [
                _c("div", { staticClass: "card-header pb-1" }, [
                  _c("div", { staticClass: "card-title" }, [
                    _c("h4", { staticClass: "mt-1" }, [
                      _vm._v(_vm._s(_vm.trans("auth.login"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "px-2" }, [
                  _vm._v("Welcome back, please login to your account."),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body pt-1" }, [
                    _c(
                      "form",
                      {
                        attrs: { id: "loginform" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                          keydown: function ($event) {
                            return _vm.loginForm.errors.clear(
                              $event.target.name
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "form-label-group form-group position-relative has-icon-left",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginForm.email,
                                  expression: "loginForm.email",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.loginForm.errors.has("email"),
                              },
                              attrs: {
                                type: "text",
                                name: "email",
                                placeholder: _vm.trans("auth.email"),
                              },
                              domProps: { value: _vm.loginForm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.loginForm,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(1),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "email" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.email"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.loginForm,
                                "prop-name": "email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "form-label-group position-relative has-icon-left",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginForm.password,
                                  expression: "loginForm.password",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.loginForm.errors.has("password"),
                              },
                              attrs: {
                                type: "password",
                                name: "password",
                                placeholder: _vm.trans("auth.password"),
                              },
                              domProps: { value: _vm.loginForm.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.loginForm,
                                    "password",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "password" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.password"))),
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.loginForm,
                                "prop-name": "password",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("div", { staticClass: "text-left" }, [
                              _vm.getConfig("recaptcha") &&
                              _vm.getConfig("login_recaptcha")
                                ? _c("div", {
                                    staticClass: "g-recaptcha",
                                    attrs: {
                                      "data-sitekey":
                                        _vm.getConfig("recaptcha_key"),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm.getConfig("reset_password")
                              ? _c(
                                  "div",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "card-link",
                                        attrs: { to: "/password" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.trans("auth.reset_here!"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.getConfig("registration")
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-outline-primary float-left btn-inline",
                                attrs: { to: "/register" },
                              },
                              [_vm._v(_vm._s(_vm.trans("auth.sign_up")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary float-right btn-inline",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(_vm._s(_vm.trans("auth.sign_in")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "login-footer" }, [
                  _vm.getConfig("social_login")
                    ? _c("div", { staticClass: "divider" }, [
                        _c("div", { staticClass: "divider-text" }, [
                          _vm._v("OR"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getConfig("social_login")
                    ? _c(
                        "div",
                        { staticClass: "footer-btn d-inline" },
                        _vm._l(_vm.social_login_providers, function (provider) {
                          return _vm.getConfig(provider + "_login")
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.trans("auth.login_with", {
                                        type: provider,
                                      }),
                                      expression:
                                        "trans('auth.login_with',{type:provider})",
                                    },
                                  ],
                                  class: ["btn", "btn-" + provider],
                                  attrs: { href: `/auth/social/${provider}` },
                                },
                                [
                                  _c("span", {
                                    class: ["fab", "fa-" + provider],
                                  }),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0",
      },
      [
        _c("img", {
          attrs: {
            src: "images/pages/login.png",
            width: "260px",
            alt: "branding logo",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-user" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-lock" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }