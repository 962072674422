var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("permission.permission")) +
                  "\n                        "
              ),
              _vm.permissions
                ? _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("general.total_result_found", {
                          count: _vm.permissions.total,
                        })
                      )
                    ),
                  ])
                : _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found"))),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.trans("permission.permission"))),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c("div", { staticClass: "dropdown" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown-menu dropdown-menu-right" },
                [
                  _c(
                    "a",
                    { staticClass: "dropdown-item", attrs: { href: "#" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary btn-sm pull-right m-r-10",
                          on: {
                            click: function ($event) {
                              _vm.showCreatePanel = !_vm.showCreatePanel
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "feather icon-plus" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.trans("permission.add_new_permission"))
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/configuration/permission/assign" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary btn-sm pull-right m-r-10",
                        },
                        [
                          _c("i", { staticClass: "feather icon-user-plus" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v(
                              _vm._s(_vm.trans("permission.assign_permission"))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showCreatePanel
                  ? _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            _vm._s(_vm.trans("permission.add_new_permission"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("permission-form", {
                              on: {
                                completed: _vm.getPermissions,
                                cancel: function ($event) {
                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.permissions.total
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("permission.name"))),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm.trans("general.action"))),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.permissions.data,
                                function (permission) {
                                  return _c("tr", [
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.toWord(permission.name)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "table-option" }, [
                                      _c("div", { staticClass: "btn-group" }, [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "confirm",
                                                rawName: "v-confirm",
                                                value: {
                                                  ok: _vm.confirmDelete(
                                                    permission
                                                  ),
                                                },
                                                expression:
                                                  "{ok: confirmDelete(permission)}",
                                              },
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "permission.delete_permission"
                                                ),
                                                expression:
                                                  "trans('permission.delete_permission')",
                                              },
                                            ],
                                            key: permission.id,
                                            staticClass:
                                              "btn btn-danger btn-sm",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "feather icon-trash-2",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.permissions.total
                      ? _c("module-info", {
                          attrs: {
                            module: "permission",
                            title: "module_info_title",
                            description: "module_info_description",
                            icon: "lock",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterPermissionForm.page_length,
                        records: _vm.permissions,
                      },
                      on: {
                        "update:pageLength": function ($event) {
                          return _vm.$set(
                            _vm.filterPermissionForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function ($event) {
                          return _vm.$set(
                            _vm.filterPermissionForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getPermissions,
                      },
                      nativeOn: {
                        change: function ($event) {
                          return _vm.getPermissions.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "feather icon-settings" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }