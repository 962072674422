<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('mail.mail_configuration')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Configurations</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{trans('mail.mail_configuration')}}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/home')"><i class="feather icon-home"></i> {{trans('general.home')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <show-tip module="mail" tip="tip_mail_configuration"></show-tip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('mail.mail')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form @submit.prevent="submit" @keydown="configForm.errors.clear($event.target.name)">
                                        <div class="row">
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">{{trans('mail.driver')}}</label>
                                            <select v-model="configForm.driver" class="custom-select col-12"  :class="{ 'is-invalid': configForm.errors.has('driver') }">
                                              <option v-for="option in mail_drivers" v-bind:value="option.value">
                                                {{ option.text }}
                                              </option>
                                            </select>
                                            <show-error :form-name="configForm" prop-name="driver"></show-error>
                                        </div>
                                        <div class="form-group">
                                            <label for="">{{trans('mail.from_name')}}</label>
                                            <input class="form-control"  :class="{ 'is-invalid': configForm.errors.has('from_name') }" type="text" value="" v-model="configForm.from_name" name="from_name" :placeholder="trans('mail.from_name')">
                                            <show-error :form-name="configForm" prop-name="from_name"></show-error>
                                        </div>
                                        <div class="form-group">
                                            <label for="">{{trans('mail.from_address')}}</label>
                                            <input class="form-control"  :class="{ 'is-invalid': configForm.errors.has('from_address') }" type="text" value="" v-model="configForm.from_address" name="from_address" :placeholder="trans('mail.from_address')">
                                            <show-error :form-name="configForm" prop-name="from_address"></show-error>
                                        </div>
                                        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">{{trans('general.save')}}</button>
                                    </div>
                                    <div class="col-12 col-sm-8">
                                        <div v-if="configForm.driver === 'mailgun'">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.domain')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_domain') }" type="text" value="" v-model="configForm.mailgun_domain" name="mailgun_domain" :placeholder="trans('mail.domain')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_domain"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.secret')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_secret') }" type="text" value="" v-model="configForm.mailgun_secret" name="mailgun_secret" :placeholder="trans('mail.secret')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_secret"></show-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="configForm.driver === 'mandrill'">
                                            <div class="form-group">
                                                <label for="">{{trans('mail.secret')}}</label>
                                                <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mandrill_secret') }" type="text" value="" v-model="configForm.mandrill_secret" name="mandrill_secret" :placeholder="trans('mail.secret')">
                                                <show-error :form-name="configForm" prop-name="mandrill_secret"></show-error>
                                            </div>
                                        </div>
                                        <div v-if="configForm.driver === 'smtp'">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.host')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('smtp_host') }" type="text" value="" v-model="configForm.smtp_host" name="smtp_host" :placeholder="trans('mail.host')">
                                                        <show-error :form-name="configForm" prop-name="smtp_host"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.port')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('smtp_port') }" type="text" value="" v-model="configForm.smtp_port" name="smtp_port" :placeholder="trans('mail.port')">
                                                        <show-error :form-name="configForm" prop-name="smtp_port"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.username')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('smtp_username') }" type="text" value="" v-model="configForm.smtp_username" name="smtp_username" :placeholder="trans('mail.username')">
                                                        <show-error :form-name="configForm" prop-name="smtp_username"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.password')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('smtp_password') }" type="text" value="" v-model="configForm.smtp_password" name="smtp_password" :placeholder="trans('mail.password')">
                                                        <show-error :form-name="configForm" prop-name="smtp_password"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.encryption')}} <show-tip type="field" module="mail" tip="tip_encryption"></show-tip> </label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('smtp_encryption') }" type="text" value="" v-model="configForm.smtp_encryption" name="smtp_encryption" :placeholder="trans('mail.encryption')">
                                                        <show-error :form-name="configForm" prop-name="smtp_encryption"></show-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="configForm.driver === 'mailgun'">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.host')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_host') }" type="text" value="" v-model="configForm.mailgun_host" name="mailgun_host" :placeholder="trans('mail.host')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_host"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.port')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_port') }" type="text" value="" v-model="configForm.mailgun_port" name="mailgun_port" :placeholder="trans('mail.port')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_port"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.username')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_username') }" type="text" value="" v-model="configForm.mailgun_username" name="mailgun_username" :placeholder="trans('mail.username')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_username"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.password')}}</label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_password') }" type="text" value="" v-model="configForm.mailgun_password" name="mailgun_password" :placeholder="trans('mail.password')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_password"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">{{trans('mail.encryption')}} <show-tip type="field" module="mail" tip="tip_encryption"></show-tip> </label>
                                                        <input class="form-control" :class="{ 'is-invalid': configForm.errors.has('mailgun_encryption') }" type="text" value="" v-model="configForm.mailgun_encryption" name="mailgun_encryption" :placeholder="trans('mail.encryption')">
                                                        <show-error :form-name="configForm" prop-name="mailgun_encryption"></show-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </form>

            

            
        </div>

        
    </div>
</template>

<script>
    export default {
        components : { },
        data() {
            return {
                configForm: new Form({
                    driver : '',
                    from_name: '',
                    from_address: '',
                    smtp_host: '',
                    smtp_port: '',
                    smtp_username: '',
                    smtp_password: '',
                    smtp_encryption: '',
                    mailgun_host: '',
                    mailgun_port: '',
                    mailgun_username: '',
                    mailgun_password: '',
                    mailgun_encryption: '',
                    mailgun_domain: '',
                    mailgun_secret: '',
                    mandrill_secret: '',
                    config_type: ''
                }, false),
                mail_drivers: []
            };
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            axios.get('/api/configuration/variable?type=mail')
                .then(response => response.data)
                .then(response => {
                    this.mail_drivers = response.mail_drivers;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
            axios.get('/api/configuration')
                .then(response => response.data)
                .then(response => {
                    this.configForm = helper.formAssign(this.configForm, response);
                }).catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
            submit(){
                this.configForm.config_type = 'mail';
                this.configForm.post('/api/configuration')
                    .then(response => {
                        this.$store.dispatch('setConfig',this.configForm);
                        toastr.success(response.message);
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
