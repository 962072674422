<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('todo.todo')}}
                            <span class="card-subtitle" v-if="todos">{{trans('general.total_result_found',{'count' : todos.total})}}</span>
                            <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item active">Todos</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-settings"></i></button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#">
                            <button class="btn btn-primary btn-sm pull-right m-r-10" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> <span class="d-none d-sm-inline">{{trans('general.filter')}}</span></button>
                        </a>
                        <a class="dropdown-item" href="#">
                            <button class="btn btn-primary btn-sm pull-right m-r-10" v-if="todos.total && !showCreatePanel" @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i> <span class="d-none d-sm-inline">{{trans('todo.add_new_todo')}}</span></button> 
                        </a>
                    </div>
                </div>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="card border-bottom" v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('todo.add_new_todo')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <todo-form @completed="getTodos" @cancel="showCreatePanel = !showCreatePanel"></todo-form>
                            </div>
                            </div>
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('todo.keyword')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterTodoForm.keyword">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <switches v-model="filterTodoForm.status" theme="bootstrap" color="success"></switches> {{trans('todo.show_completed')}}
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <date-range-picker :start-date.sync="filterTodoForm.start_date" :end-date.sync="filterTodoForm.end_date" :label="trans('general.date_between')"></date-range-picker>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="todos.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterTodoForm.sort_by" :order="filterTodoForm.order" @updateSortBy="value => {filterTodoForm.sort_by = value}"  @updateOrder="value => {filterTodoForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="todos.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('todo.title')}}</th>
                                            <th>{{trans('todo.date')}}</th>
                                            <th>{{trans('todo.status')}}</th>
                                            <th>{{trans('todo.completed_at')}}</th>
                                            <th>{{trans('todo.description')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="todo in todos.data">
                                            <td v-text="todo.title"></td>
                                            <td>{{todo.date | moment}}</td>
                                            <td v-html="getStatus(todo)"></td>
                                            <td>{{todo.completed_at | momentDateTime}}</td>
                                            <td v-text="todo.description"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-primary btn-sm" v-tooltip="todo.status ? trans('todo.mark_as_incomplete') : trans('todo.mark_as_complete')" @click.prevent="toggleStatus(todo)">
                                                        <i :class="['feather', (todo.status ?  'icon-x' : 'icon-check')]"></i>
                                                    </button>
                                                    <button class="btn btn-info btn-sm" v-tooltip="trans('todo.edit_todo')" @click.prevent="editTodo(todo)"><i class="feather icon-edit"></i></button>
                                                    <button class="btn btn-danger btn-sm" :key="todo.id" v-confirm="{ok: confirmDelete(todo)}" v-tooltip="trans('todo.delete_todo')"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!todos.total" module="todo" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" v-if="!showCreatePanel" @click="showCreatePanel = !showCreatePanel"><i class="feather icon-plus"></i> {{trans('general.add_new')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterTodoForm.page_length" :records="todos" @updateRecords="getTodos"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import todoForm from './form'
    import switches from 'vue-switches'
    import datepicker from 'vuejs-datepicker'
    import dateRangePicker from '../../components/date-range-picker'
    import sortBy from '../../components/sort-by'

    export default {
        components : { todoForm,switches,datepicker,dateRangePicker,sortBy },
        data() {
            return {
                todos: {
                    total: 0,
                    data: []
                },
                filterTodoForm: {
                    keyword: '',
                    status: false,
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
                orderByOptions: [
                    {
                        value: 'title',
                        translation: i18n.todo.title
                    },
                    {
                        value: 'description',
                        translation: i18n.todo.description
                    },
                    {
                        value: 'created_at',
                        translation: i18n.todo.date
                    },
                    {
                        value: 'status',
                        translation: i18n.todo.status
                    }
                ],
                showCreatePanel: false,
                showFilterPanel: false
            };
        },
        mounted(){
            if(!helper.hasPermission('access-todo')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('todo')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getTodos();
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getTodos(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterTodoForm);
                axios.get('/api/todo?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.todos = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            editTodo(todo){
                this.$router.push('/todo/'+todo.id+'/edit');
            },
            confirmDelete(todo){
                return dialog => this.deleteTodo(todo);
            },
            deleteTodo(todo){
                axios.delete('/api/todo/'+todo.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getTodos();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStatus(todo){
                return todo.status ? ('<span class="badge badge-success">'+i18n.todo.complete+'</span>') : ('<span class="badge badge-danger">'+i18n.todo.incomplete+'</span>') ;
            },
            toggleStatus(todo){
                axios.post('/api/todo/'+todo.id+'/status')
                    .then(response => response.data)
                    .then(response => {
                        this.getTodos();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            }
        },
        filters: {
          moment(date) {
            return helper.formatDate(date);
          },
          momentDateTime(date) {
            return helper.formatDateTime(date);
          }
        },
        watch: {
            filterTodoForm: {
                handler(val){
                    setTimeout(() => {
                        this.getTodos();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
