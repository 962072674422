var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(_vm._s(_vm.title) + " Beneficiaries Graph"),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "content-header-right text-md-right col-md-3 col-12 d-md-block d-none",
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("h2", { staticClass: "content-header-title" }, [_vm._v("Statistics")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex flex-column align-items-start pb-0",
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "router-link",
                  { staticClass: "primary", attrs: { to: "/beneficiaries" } },
                  [
                    _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                      _vm._v(_vm._s(_vm.beneficiaries)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [_vm._v("Beneficiaries")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex flex-column align-items-start pb-0",
              },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "router-link",
                  { staticClass: "success", attrs: { to: "/schools" } },
                  [
                    _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                      _vm._v(_vm._s(_vm.schools)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [_vm._v("Schools")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex flex-column align-items-start pb-0",
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "router-link",
                  { staticClass: "danger", attrs: { to: "/payments" } },
                  [
                    _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                      _vm._v(_vm._s(_vm.payments)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [_vm._v("Payments")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header d-flex flex-column align-items-start pb-0",
              },
              [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "warning",
                    attrs: { to: "/beneficiaries/enrolled" },
                  },
                  [
                    _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                      _vm._v(_vm._s(_vm.enrollments)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v("Enrolled Beneficiaries"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12 col-md-6 col-12" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "card-content" }, [
              _c(
                "div",
                { staticClass: "card-body pb-0" },
                [
                  _c("beneficiaryChart", {
                    attrs: { data: _vm.getChartData, label: _vm.getChartLabel },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "/home" } }, [_vm._v("Home")]),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _vm._v(
            "\n                                 Beneficiaries\n                             "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v(
            "\n                                 Graph\n                             "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-primary p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-users text-primary font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-database text-success font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-danger font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-warning p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-users text-warning font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-end",
      },
      [
        _c("h4", { staticClass: "card-title" }, [
          _vm._v("Selected Beneficiaries"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-medium-5 mb-0" }, [
          _c("i", {
            staticClass: "feather icon-settings text-muted cursor-pointer",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }