<template>
	<div v-if="!type" class="alert alert-info" role="alert">
		<span>
        	<i class="feather icon-alert-triangle mr-1 align-middle"></i> {{trans(module+'.'+tip)}}
    	</span>
	</div>

    <div class="position-relative d-inline-block" v-else-if="type">
        <span class="text-warning" data-toggle="tooltip" data-placement="right" :title="trans(module+'.'+tip)">
        <i class="feather icon-alert-circle"></i>
        </span>
    </div>
    
</template>

<script>
    export default {
        props: ['tip','module','type']
    }
</script>
