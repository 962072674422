<template>
    <div>
        <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">{{ title }} Beneficiaries Graph</h2>
                    <div class="breadcrumb-wrapper col-12">
                        
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/home">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    Beneficiaries
                                </li>
                                <li class="breadcrumb-item active">
                                    Graph
                                </li>
                            </ol> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
            
        </div>
    </div>

    <div class="content-body">
        <h2 class="content-header-title">Statistics</h2>
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-primary p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-primary font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/beneficiaries" class="primary">
                              <h2 class="text-bold-700 mt-1">{{ beneficiaries }}</h2>
                              <p class="mb-0">Beneficiaries</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-database text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/schools" class="success">
                              <h2 class="text-bold-700 mt-1">{{ schools }}</h2>
                              <p class="mb-0">Schools</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payments" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ payments }}</h2>
                              <p class="mb-0">Payments</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-warning p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-warning font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/beneficiaries/enrolled" class="warning">
                              <h2 class="text-bold-700 mt-1">{{ enrollments }}</h2>
                              <p class="mb-0">Enrolled Beneficiaries</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
            </div>
          <div class="row">
              <div class="col-lg-12 col-md-6 col-12">
                  <div class="card">
                      <div class="card-header d-flex justify-content-between align-items-end">
                          <h4 class="card-title">Selected Beneficiaries</h4>
                          <p class="font-medium-5 mb-0"><i class="feather icon-settings text-muted cursor-pointer"></i></p>
                      </div>
                      <div class="card-content">
                          <div class="card-body pb-0">
                              <beneficiaryChart :data="getChartData" :label="getChartLabel" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
        </section>
   
   </div>
    </div>
</template>

<script>
import beneficiaryChart from '../../components/beneficiary-chart'
    export default {
        components: { beneficiaryChart },
        data() {
            return {
                stateId:this.$route.params.stateId,
                title: '',
                beneficiaries: 0,
                schools: 0,
                enrollments: 0,
                payments: 0,
                chartData: [],
                chartLabel: []
            }
        },
        mounted(){
            
            axios.get('/api/beneficiaries/state-graph/'+this.stateId)
                .then(response => response.data)
                .then(response => {
                    this.title = response.title[0];
                    this.beneficiaries = response.beneficiaries;
                    this.schools = response.schools;
                    this.enrollments = response.enrollments;
                    this.payments = response.payments;
                    this.chartLabel = response.labels;
                    this.chartData = response.data;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })

        },
        computed: {
          getChartData() {
            return this.chartData
          },
          getChartLabel() {
            return this.chartLabel
          }
        }
       
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>