<template>
    <div class="sidebar-left">
        <div class="sidebar">
            <div class="sidebar-content email-app-sidebar d-flex">
        
        <div class="email-app-menu">
            <div class="form-group form-group-compose text-center compose-btn">
            <router-link to="/message/compose" class="btn btn-primary btn-block my-2">
                    <i class="feather icon-edit"></i> {{trans('message.compose')}}
                
                </router-link>
            </div>
            <div class="sidebar-menu-list">
            <div class="list-group list-group-messages font-medium-1">
                <router-link to="/message/inbox" :class="[menu === 'inbox' ? 'active' : '', 'list-group-item list-group-item-action border-0 pt-0']">
                    <i class="font-medium-5 feather icon-mail mr-50"></i>
                    {{trans('message.inbox')}}
                    <span v-if="statistics.inbox"
                    class="badge badge-primary badge-pill float-right">{{statistics.inbox}}</span>
                </router-link>

                <a href="#" class="list-group-item list-group-item-action border-0"><i
                    class="font-medium-5 fa fa-paper-plane-o mr-50"></i> Sent</a>

                <router-link to="/message/sent" :class="[menu === 'sent' ? 'active' : '', 'list-group-item list-group-item-action border-0']">
                    <i
                    class="font-medium-5 fa fa-paper-plane-o mr-50"></i>
                    {{trans('message.sent_box')}}
                    <span v-if="statistics.sent"
                    class="badge badge-primary badge-pill float-right">{{statistics.sent}}</span>
            </router-link>

            <router-link to="/message/draft" :class="[menu === 'draft' ? 'active' : '', 'list-group-item list-group-item-action border-0']">
                    <i
                    class="font-medium-5 feather icon-edit-2 mr-50"></i>
                    {{trans('message.draft')}}
                    <span v-if="statistics.draft"
                    class="badge badge-primary badge-pill float-right">{{statistics.draft}}</span>
            </router-link>

            <router-link to="/message/important" :class="[menu === 'important' ? 'active' : '', 'list-group-item list-group-item-action border-0']">
                    <i
                    class="font-medium-5 feather icon-star mr-50"></i>
                    {{trans('message.important')}}
            </router-link>

            <router-link to="/message/trash" :class="[menu === 'trash' ? 'active' : '', 'list-group-item list-group-item-action border-0']">
                    <i
                    class="font-medium-5 feather icon-trash-2 mr-50"></i>
                    {{trans('message.trash')}}
                    <span v-if="statistics.trash"
                    class="badge badge-primary badge-pill float-right">{{statistics.trash}}</span>
            </router-link>
            </div>
            
            </div>
        </div>
    </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            menu: {
                default: ''
            },
            statistics: {

            }
        },
        mounted(){
        }
    }
</script>
