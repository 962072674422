<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('general.menu')}}
                         </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Configurations</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('general.menu')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/home')"><i class="feather icon-home"></i> {{trans('general.home')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <show-tip module="configuration" tip="tip_menu"></show-tip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('general.menu')}}</h4>
                            </div>
                            
                        <div class="card-content">
                            <div class="card-body">
                                <form @submit.prevent="submit" @keydown="configForm.errors.clear($event.target.name)">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('user.user')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_user_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('todo.todo')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_todo_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('message.message')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_message_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('configuration.configuration')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_configuration_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('backup.backup')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_backup_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('template.email_template')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_email_template_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('mail.email_log')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_email_log_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('activity.activity_log')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_activity_log_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('message.message')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_message_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('sms.sms')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_sms_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('mail.email')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_email_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Show Settings Menu</td>
                                                <td>
                                                    <switches v-model="configForm.show_settings_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('permission.permission')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_permission_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{trans('configuration.show_menu',{menu: trans('general.scheduled_job')})}}</td>
                                                <td>
                                                    <switches v-model="configForm.show_schedule_job_menu" theme="bootstrap" color="success"></switches>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button type="submit" class="btn btn-primary waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.save')}}</button>
                            </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            
        </div>

        
    </div>
</template>

<script>
    import switches from 'vue-switches'

    export default {
        components : { switches },
        data() {
            return {
                configForm: new Form({
                    show_user_menu: 0,
                    show_todo_menu: 0,
                    show_message_menu: 0,
                    show_configuration_menu: 0,
                    show_backup_menu: 0,
                    show_email_template_menu: 0,
                    show_email_log_menu: 0,
                    show_activity_log_menu: 0,
                    show_message_menu: 0,
                    show_sms_menu: 0,
                    show_email_menu: 0,
                    show_settings_menu: 0,
                    show_permission_menu: 0,
                    show_schedule_job_menu: 0,
                    config_type: 'menu'
                },false)
            }
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            this.configForm.show_user_menu = helper.getConfig('show_user_menu');
            this.configForm.show_todo_menu = helper.getConfig('show_todo_menu');
            this.configForm.show_message_menu = helper.getConfig('show_message_menu');
            this.configForm.show_configuration_menu = helper.getConfig('show_configuration_menu');
            this.configForm.show_backup_menu = helper.getConfig('show_backup_menu');
            this.configForm.show_email_template_menu = helper.getConfig('show_email_template_menu');
            this.configForm.show_email_log_menu = helper.getConfig('show_email_log_menu');
            this.configForm.show_activity_log_menu = helper.getConfig('show_activity_log_menu');
            this.configForm.show_message_menu = helper.getConfig('show_message_menu');
            this.configForm.show_sms_menu = helper.getConfig('show_sms_menu');
            this.configForm.show_email_menu = helper.getConfig('show_email_menu');
            this.configForm.show_settings_menu = helper.getConfig('show_settings_menu');
            this.configForm.show_permission_menu = helper.getConfig('show_permission_menu');
            this.configForm.show_schedule_job_menu = helper.getConfig('show_schedule_job_menu');
        },
        methods: {
            submit(){
                this.configForm.config_type = 'menu';
                this.configForm.show_user_menu = (this.configForm.show_user_menu) ? 1 : 0;
                this.configForm.show_todo_menu = (this.configForm.show_todo_menu) ? 1 : 0;
                this.configForm.show_message_menu = (this.configForm.show_message_menu) ? 1 : 0;
                this.configForm.show_configuration_menu = (this.configForm.show_configuration_menu) ? 1 : 0;
                this.configForm.show_backup_menu = (this.configForm.show_backup_menu) ? 1 : 0;
                this.configForm.show_email_template_menu = (this.configForm.show_email_template_menu) ? 1 : 0;
                this.configForm.show_email_log_menu = (this.configForm.show_email_log_menu) ? 1 : 0;
                this.configForm.show_activity_log_menu = (this.configForm.show_activity_log_menu) ? 1 : 0;
                this.configForm.show_message_menu = (this.configForm.show_message_menu) ? 1 : 0;
                this.configForm.show_sms_menu = (this.configForm.show_sms_menu) ? 1 : 0;
                this.configForm.show_email_menu = (this.configForm.show_email_menu) ? 1 : 0;
                this.configForm.show_settings_menu = (this.configForm.show_settings_menu) ? 1 : 0;
                this.configForm.show_permission_menu = (this.configForm.show_permission_menu) ? 1 : 0;
                this.configForm.show_schedule_job_menu = (this.configForm.show_schedule_job_menu) ? 1 : 0;
                this.configForm.post('/api/configuration')
                    .then(response => {
                        this.$store.dispatch('setConfig',this.configForm);
                        toastr.success(response.message);
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
