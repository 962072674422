<template>
    <section class="row flexbox-container">
  <div class="col-xl-8 col-11 d-flex justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0">
                  <img src="images/pages/login.png" width="260px" alt="branding logo">
              </div>
              <div class="col-lg-6 col-12 p-0">
                  <div class="card rounded-0 mb-0 px-2">
                      <div class="card-header pb-1">
                          <div class="card-title">
                              <h4 class="mt-1">{{trans('auth.login')}}</h4>
                          </div>
                      </div>
                      <p class="px-2">Welcome back, please login to your account.</p>
                      <div class="card-content">
                          <div class="card-body pt-1">
                            <form id="loginform" @submit.prevent="submit" @keydown="loginForm.errors.clear($event.target.name)">
                              <fieldset class="form-label-group form-group position-relative has-icon-left">

                                  <input type="text" name="email" class="form-control" :class="{ 'is-invalid': loginForm.errors.has('email') }" :placeholder="trans('auth.email')" v-model="loginForm.email">
                            

                                  <div class="form-control-position">
                                      <i class="feather icon-user"></i>
                                  </div>
                                  <label for="email">{{ trans('auth.email') }}</label>
                                    

                                      <show-error :form-name="loginForm" prop-name="email"></show-error>
 
                              </fieldset>

                              <fieldset class="form-label-group position-relative has-icon-left">

                                  <input type="password" name="password" class="form-control" :class="{ 'is-invalid': loginForm.errors.has('password') }" :placeholder="trans('auth.password')" v-model="loginForm.password">

                                  <div class="form-control-position">
                                      <i class="feather icon-lock"></i>
                                  </div>
                                  <label for="password">{{ trans('auth.password') }}</label>
                                  <show-error :form-name="loginForm" prop-name="password"></show-error>
                                    
                              </fieldset>
                              <div class="form-group d-flex justify-content-between align-items-center">
                                  <div class="text-left">
                                    <div class="g-recaptcha" v-if="getConfig('recaptcha') && getConfig('login_recaptcha')" :data-sitekey="getConfig('recaptcha_key')"></div>
                                  </div>
                                  
                                    <div v-if="getConfig('reset_password')" class="text-right">
                                        <router-link to="/password" class="card-link">{{trans('auth.reset_here!')}}</router-link>
                                    </div>

                              </div>
                              <router-link to="/register" class="btn btn-outline-primary float-left btn-inline" v-if="getConfig('registration')">{{trans('auth.sign_up')}}</router-link>
                              <button type="submit" class="btn btn-primary float-right btn-inline">{{trans('auth.sign_in')}}</button>
                            </form>
                          </div>
                      </div>
                      <div class="login-footer">
                        <div class="divider" v-if="getConfig('social_login')">
                          <div class="divider-text">OR</div>
                        </div>
                        <div class="footer-btn d-inline" v-if="getConfig('social_login')">
                            <a v-for="provider in social_login_providers" v-if="getConfig(provider+'_login')" :href="`/auth/social/${provider}`" :class="['btn','btn-'+provider]" v-tooltip="trans('auth.login_with',{type:provider})"> <span :class="['fab','fa-'+provider]"></span></a>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    
</template>

<script>
    import guestFooter from '../../layouts/guest-footer.vue'

    export default {
        data() {
            return {
                loginForm: new Form({
                    email: '',
                    password: ''
                }),
                social_login_providers: []
            }
        },
        components: {
            guestFooter
        },
        mounted(){
            if(helper.getConfig('social_login'))
            axios.get('/api/configuration/variable?type=social_login')
                .then(response => response.data)
                .then(response => {
                    this.social_login_providers = response.social_login_providers;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
            submit(){
                this.loginForm.post('/api/auth/login')
                    .then(response =>  {
                        localStorage.setItem('auth_token',response.token);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token');
                        this.$store.dispatch('setAuthStatus');
                        this.$store.dispatch('setLastActivity');
                        toastr.success(response.message);

                        if(helper.getConfig('two_factor_security') && response.two_factor_code){
                            this.$store.dispatch('setTwoFactorCode',response.two_factor_code);
                            this.$router.push('/auth/security');
                        }
                        else {
                            var redirect_path = response.reload ? '/home?reload=1' : '/home';
                            this.$store.dispatch('resetTwoFactorCode');
                            this.$router.push(redirect_path);
                        }
                    }).catch(error => {
                        helper.showErrorMsg(error);
                        toastr.error(error.message);
                    });
            },
            loginAsAdmin(){
                this.loginForm.email = 'john.doe@example.com';
                this.loginForm.password = 'password';
                this.submit();
            },
            loginAsUser(){
                this.loginForm.email = 'marry.jen@example.com';
                this.loginForm.password = 'password';
                this.submit();
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
