<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Upload Payments
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/payment">
                                        Payments
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    Upload Payments
                                </li>


                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/payments')"><i class="feather icon-list"></i> Payment Lists</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Upload Payments</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <form @submit.prevent="proceed" @keydown="paymentForm.errors.clear($event.target.name)">
                                    <div class="row">
                                        
        <div class="col-md-12 mb-1">
            <a href="/public/samples/payments.xlsx" download class="btn btn-primary btn-sm waves-effect waves-float waves-light pull-left"><i class="fas fa-download"></i> {{ trans('general.download_sample') }}</a>
            
        </div>
            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.cohort')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('cohort_id') }" name="cohort_id" v-model="paymentForm.cohort_id">
                        <option value="">{{trans('beneficiary.cohort')}}</option>
                        <option v-for="cohort in cohorts" :value="cohort.id">{{cohort.name}}</option>
                    </select>
                    <show-error :form-name="paymentForm" prop-name="cohort_id"></show-error>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('payment.payment_schedule')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('payment_schedule_id') }" name="cohort_id" v-model="paymentForm.payment_schedule_id">
                        <option value="">{{trans('payment.payment_schedule')}}</option>
                        <option v-for="paymentSchedule in paymentSchedules" :value="paymentSchedule.id">{{paymentSchedule.name}}</option>
                    </select>
                    <show-error :form-name="paymentForm" prop-name="payment_schedule_id"></show-error>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <file-upload-input :button-text="trans('general.excel')" :token="paymentForm.upload_token" module="beneficiary" :clear-file="clear_message_attachment" :module-id="paymentForm.id"></file-upload-input>    
                </div>
            </div>
                                        
                                        
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-4">
                    <button-spinner
                        :is-loading="paymentForm.isLoading" 
                        :disabled="paymentForm.isLoading"
                        
                        class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right"
                        style="height: 39px; color: #fff;"
                        >
                        <span>{{trans('general.save')}}</span>
                    </button-spinner>

                    <router-link to="/payments" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}</router-link>
        
                </div>
            </div>
        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import fileUploadInput from '../../components/file-upload-input'
    import uuid from 'uuid/v4'

    export default {
        components : { fileUploadInput },
        data() {
            return {
                paymentForm: new Form({
                    isLoading: false,
                    id: '',
                    upload_token: '',
                    cohort_id: '',
                    payment_schedule_id: ''
                }),
                cohorts: [],
                paymentSchedules: [],
                clear_message_attachment: false,
            };
        },
        mounted(){
            if(!helper.hasPermission('upload-payment')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('payment')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
            this.paymentForm.upload_token = uuid();
            this.fetchPreRequisites();
        },
         methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cohorts = response.cohorts;
                        this.paymentSchedules = response.paymentSchedules;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                this.storeUpload();
            },
            storeUpload(){
                this.paymentForm.isLoading = true;
                this.paymentForm.post('/api/payments/import-payments')
                    .then(response => {
                        this.paymentForm.isLoading = false;
                        toastr.success(response.message);
                        this.clear_message_attachment = true;
                        this.$router.push('/payments');
                    })
                    .catch(error => {
                        this.paymentForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
