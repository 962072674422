<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('school.school')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/school">
                                        {{ trans('school.school') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('school.school_details') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/schools/create')"><i class="feather icon-plus"></i> {{trans('school.create_school')}}</button>

                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/schools')"><i class="feather icon-list"></i> {{trans('school.school_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title float-left mb-0">{{trans('school.school_details')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-12">
                                <div class="row">
                                    
                                    <div class="col-sm-12">
                                        <div class="card">
                                          <div class="card-header">
                                            <h4>GPS Location</h4>
                                            <i class="feather icon-more-horizontal cursor-pointer"></i>
                                          </div>
                                          <div class="card-body">
                                            
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-8 col-12">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card">
                                          <div class="card-header">
                                            <h4>{{trans('school.school_details')}}</h4>
                                            <i class="feather icon-more-horizontal cursor-pointer"></i>
                                          </div>
                                          <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('school.code')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="school.code">
                                                           
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('school.name')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="school.name">
                                                            
                                                        </div>
                                                    </div>
                        
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('school.address')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="school.address">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('school.gps_location')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="school.gps_location">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('school.lga')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="school.lga.name">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                id:this.$route.params.id,
                school: ''
            }
        },
        mounted(){
            if(!helper.hasPermission('list-schools')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('school')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getSchool();
        },

        methods: {
            getSchool(){
                
                axios.get('/api/schools/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.school = response.school;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/school');
                    });
            }
        }
    }
</script>
