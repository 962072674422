var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("textarea", {
    ref: "txtArea",
    staticClass: "form-control",
    attrs: { rows: _vm.rows, placeholder: _vm.placeholder, name: _vm.name },
    domProps: { value: _vm.value },
    on: { change: _vm.updateContent },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }