<template>
	
<section class="row flexbox-container">
  <div class="col-xl-7 col-md-8 col-12 d-flex justify-content-center">
    <div class="card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
      <div class="card-content">
        <div class="card-body text-center">
          <img src="images/pages/404.png" class="img-fluid align-self-center" alt="branding logo">
          <h1 class="font-large-2 my-1">{{trans('general.page_not_found_heading')}}</h1>
          <p class="p-2">
            {{trans('general.page_not_found_message')}}
          </p>
          <router-link to="/" class="btn btn-primary btn-lg mt-2">{{trans('general.back_to_home')}}</router-link> 
          
        </div>
      </div>
    </div>
  </div>
</section>

</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
