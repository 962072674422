<template>
        <!-- BEGIN: Content-->
        <div class="app-content content">
            <div class="content-wrapper">
                <div class="content-body">

                    <router-view></router-view>

                </div>
            </div>
        </div>
        <!-- End: Content-->
</template>

<script>
    export default {
        methods : {
        },
        mounted() {
            $('body').removeClass('navbar-sticky fixed-footer');
            $('body').addClass('bg-full-screen-image blank-page');
            helper.notification();
        },
        destroyed(){
        }
    }
</script>