<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('calendar.calendar')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('calendar.calendar')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <div class="dropdown">
                        <button class="btn btn-primary btn-sm pull-right" @click="showCreatePanel = !showCreatePanel"><i class="fas fa-calendar"></i> <span class="d-none d-sm-inline">{{trans('calendar.add_new_event')}}</span></button>

                    
                    
                </div>
                    
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <transition name="fade">
                        <div class="card border-bottom" v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('calendar.add_new_event')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <event-form @completed="getEvents" @cancel="showCreatePanel = !showCreatePanel"></event-form>
                            </div>
                            </div>
                        </div>
                    </transition>

                        <full-calendar ref="calendar" :event-sources="eventSources" @event-selected="eventSelected" @event-created="eventCreated" :config="config"></full-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
    import eventForm from './form'
    import moment from 'moment';
    import { FullCalendar } from 'vue-full-calendar'
    export default {
        name: 'app',
        components: {
            FullCalendar, eventForm
        },
      data() {
        return {
          events: {},
          showCreatePanel: false,
          config: {
            defaultView: 'month',
                eventRender: function(event, element) {
                console.log(event)
            },
          },
          selected: {},
        };
      },
      mounted(){
            if(!helper.hasPermission('access-calendar')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('calendar')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getEvents();
        },
      methods: {
        hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getEvents(){
                axios.get('/api/calendar')
                    .then(response => this.events = response.data)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
        refreshEvents() {
          this.$refs.calendar.$emit('refetch-events');
        },
        removeEvent() {
          this.$refs.calendar.$emit('remove-event', this.selected);
          this.selected = {};
        },
        eventSelected(event) {
          this.selected = event;
        },
        eventCreated(...test) {
          console.log(test);
        },
      },
      computed: {
        eventSources() {
          const self = this;
          return [
            {
              events(start, end, timezone, callback) {
                setTimeout(() => {
                  console.log(self.events);
                  callback(self.events.filter(() => Math.random() > 0.5));
                }, 1000);
              },
            },
          ];
        },
      },
    };
</script>

<style>
    @import '~fullcalendar/dist/fullcalendar.min.css';
    /*@import './../../../plugins/calenders/fullcalendar.css';*/
</style>
