<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('configuration.scheduled_job')}}
                         </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Configurations</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('configuration.scheduled_job')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/home')"><i class="feather icon-home"></i> {{trans('general.home')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <show-tip module="configuration" tip="tip_scheduled_job"></show-tip>
                            <p>Add below cron command in your server:</p>
                            <blockquote>
                                php /path-to-artisan schedule:run >> /dev/null 2>&1
                            </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('configuration.scheduled_job')}}</h4>
                            </div>
                            
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive">
                                <table class="table table-stripped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Frequency</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Birthday/Anniversary wish to Staff/Customer</td>
                                            <td>Once per day at 09:00 AM</td>
                                        </tr>
                                        <tr>
                                            <td>Daily Backup</td>
                                            <td>Once per day at 01:00 AM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            
        </div>

        
    </div>
</template>

<script>
    export default {
        components : { },
        data() {
            return {

            }
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
        },
        methods: {
        }
    }
</script>
