var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("beneficiary.beneficiary")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/beneficiaries" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("beneficiary.beneficiary")) +
                          "\n                                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("beneficiary.validate_beneficiary")) +
                      "\n                            "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/beneficiaries")
                  },
                },
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("beneficiary.beneficiary_list"))),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.trans("beneficiary.validate_beneficiary"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("beneficiaries-form", {
                attrs: { id: _vm.id, enrollment: _vm.validation },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }