<template>
    <form @submit.prevent="proceed" @keydown="attendancesForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.cohort')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': attendancesForm.errors.has('cohort_id') }" name="cohort_id" v-model="attendancesForm.cohort_id" :disabled="id">
                        <option value="">{{trans('beneficiary.cohort')}}</option>
                        <option v-for="cohort in cohorts" :value="cohort.id">{{cohort.name}}</option>
                    </select>
                    <show-error :form-name="attendancesForm" prop-name="cohort_id"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('attendance.cycle')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': attendancesForm.errors.has('cycle_id') }" name="cycle_id" v-model="attendancesForm.cycle_id">
                        <option value="">{{trans('attendance.cycle')}}</option>
                        <option v-for="cycle in cycles" :value="cycle.id">{{cycle.name}}</option>
                    </select>
                    <show-error :form-name="attendancesForm" prop-name="cycle_id"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('attendance.term')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': attendancesForm.errors.has('term_id') }" name="term_id" v-model="attendancesForm.term_id">
                        <option value="">{{trans('attendance.term')}}</option>
                        <option v-for="term in terms" :value="term.id">{{term.name}}</option>
                    </select>
                    <show-error :form-name="attendancesForm" prop-name="term_id"></show-error>
                </div>
            </div>

            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('school.school')}}</label>
                    <v-select @input="getBeneficiaries" :options="schools" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': attendancesForm.errors.has('school_id') }" name="school_id" v-model="attendancesForm.school_id"></v-select>
                    <show-error :form-name="attendancesForm" prop-name="school_id"></show-error>
                </div>
            </div>
            
            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('attendance.week')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': attendancesForm.errors.has('week') }" name="week" v-model="attendancesForm.week">
                        <option value="">{{trans('attendance.week')}}</option>
                        <option v-for="week in weeks" :value="week.name">{{week.name}}</option>
                    </select>
                    <show-error :form-name="attendancesForm" prop-name="week"></show-error>
                </div>
            </div>


            <div class="col-xl-12 col-md-12 col-12">
                <div class="table-responsive m-b-20">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Beneficiary</th>
                                <th>Admission No.</th>
                                <th>Class</th>
                                <th v-for="day in days" class="text-center">{{day.name}}</th>
                                <th>Reason</th>
                                <th>Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="beneficiary in beneficiaries">
                                <td>{{beneficiary.name}}</td>
                                <td>{{beneficiary.unique_id}}</td>
                                <td>{{beneficiary.clas.name}}</td>
                                <td class="text-center">
                                    <switches v-model="attendancesForm.monday[beneficiary.id]['attendance']" theme="bootstrap" color="success"></switches>
                                </td>
                                <td class="text-center">
                                    <switches v-model="attendancesForm.tuesday[beneficiary.id]['attendance']" theme="bootstrap" color="success"></switches>
                                </td>
                                <td class="text-center">
                                    <switches v-model="attendancesForm.wednesday[beneficiary.id]['attendance']" theme="bootstrap" color="success"></switches>
                                </td>
                                <td class="text-center">
                                    <switches v-model="attendancesForm.thursday[beneficiary.id]['attendance']" theme="bootstrap" color="success"></switches>
                                </td>
                                <td class="text-center">
                                    <switches v-model="attendancesForm.friday[beneficiary.id]['attendance']" theme="bootstrap" color="success"></switches>
                                </td>
                                <td>
                                    <input class="form-control" type="text" value="" v-model="attendancesForm.reason[beneficiary.id]" name="reason" :key="beneficiary.id"placeholder="Reason">
                                </td>
                                <td>
                                    <input class="form-control" type="text" value="" v-model="attendancesForm.remark[beneficiary.id]" name="remark" :key="beneficiary.id" placeholder="Remark">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>                              
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
                <span v-if="id">{{trans('general.update')}}</span>
                <span v-else>{{trans('general.save')}}</span>
                </button>

                <a href="#" @click="$router.go(-1)" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}
                </a>

            </div>
        </div>
    </form>
</template>


<script>
    import datepicker from 'vuejs-datepicker'
    import switches from 'vue-switches'

    export default {
        components: {datepicker, switches},
        data() {
            return {
                attendancesForm: new Form({
                    id: '',
                    beneficiary_id: '',
                    class: '',
                    cohort_id: '',
                    cycle_id: '',
                    term_id: '',
                    community: '',
                    admission_number: '',
                    week: '',
                    monday: {},
                    tuesday: {},
                    wednesday: {},
                    thursday: {},
                    friday: {},
                    reason: [],
                    remark: [],
                    class: '',
                    school_id: '',
                }),
                cohorts: [],
                schools: [],
                cycles: [],
                terms: [],
                days: [],
                weeks: [],
                beneficiaries: []
            };
        },
        props: ['id'],
        mounted() {
            if(this.id)
                this.getAttendance();
            this.fetchPreRequisites();
            
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cohorts = response.cohorts;
                        this.schools = response.schools;
                        this.cycles = response.cycles;
                        this.terms = response.terms;
                        this.days = response.days;
                        this.weeks = response.weeks;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updateAttendance();
                else
                    this.storeAttendance();
            },
            storeAttendance(){
                this.attendancesForm.post('/api/attendances')
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/attendances');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            getAttendance(){
                axios.get('/api/attendances/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.attendancesForm.id = response.beneficiary.id;
                        this.attendancesForm.cohort_id = response.beneficiary.cohort_id;
                        this.attendancesForm.cycle_id = response.beneficiary.cycle_id;
                        this.attendancesForm.term_id = response.beneficiary.term_id;
                        this.attendancesForm.community = response.beneficiary.community;
                        this.attendancesForm.admission_number = response.beneficiary.admission_number;
                        this.attendancesForm.name = response.beneficiary.name;
                        this.attendancesForm.class = response.beneficiary.class;
                        this.attendancesForm.gender = response.beneficiary.gender;
                        this.attendancesForm.school_id = response.beneficiary.school_id;

                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/attendances');
                    });
            },
            updateAttendance(){
                this.attendancesForm.date_of_birth = moment(this.attendancesForm.date_of_birth).format('YYYY-MM-DD');
                this.attendancesForm.patch('/api/attendances/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/attendances');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            getBeneficiaries(event){
                axios.get('/api/beneficiaries-by-school-id/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.beneficiaries = response.beneficiaries;
                        this.attendancesForm.monday = response.monday;
                        this.attendancesForm.tuesday = response.tuesday;
                        this.attendancesForm.wednesday = response.wednesday;
                        this.attendancesForm.thursday = response.thursday;
                        this.attendancesForm.friday = response.friday;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            }
           
        }
    }
</script>
