<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.beneficiary')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/beneficiaries">
                                        {{ trans('beneficiary.beneficiary') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('beneficiary.beneficiary_details') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/beneficiaries/create')"><i class="feather icon-plus"></i> {{trans('beneficiary.create_beneficiary')}}</button>

                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/beneficiaries')"><i class="feather icon-list"></i> {{trans('beneficiary.beneficiary_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title float-left mb-0">{{trans('beneficiary.beneficiary_details')}}</h4>

                    <button class="btn btn-primary btn-sm mr-1 mb-1 waves-effect waves-light pull-right" @click="approve"> <i class="feather icon-copy"></i> {{trans('beneficiary.approve_this_beneficiary')}}</button>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <div class="row">
                            
                            <div class="col-lg-12 col-12">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card">
                                          <div class="card-header">
                                            <h4>{{trans('beneficiary.beneficiary_details')}}</h4>
                                            <i class="feather icon-more-horizontal cursor-pointer"></i>
                                          </div>
                                          <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.eligibility')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.eligibility">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.admission_number')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.admission_number">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.name')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.name">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.email')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.email">
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.class')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.class">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.date_of_birth')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.date_of_birth">
                                                            
                                                        </div>
                                                    </div>
                        
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.address')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.address">
                                                           
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.parent_name')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.parent_name">
                                                           
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.parent_telephone_number')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.parent_telephone_number">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.bvn')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.bvn">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.nin')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.nin">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.parent_telephone_number')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.parent_telephone_number">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.voters_card')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.voters_card">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.school')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.school.name">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.emis_code')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.emis_code">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.nsr')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.nsr">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.location')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.location">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.pan')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.pan">
                                                           
                                                        </div>
                                                    </div>

                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('beneficiary.has_card')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="beneficiary.has_card">
                                                           
                                                        </div>
                                                    </div>

                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                id:this.$route.params.id,
                beneficiary: '',
            }
        },
        mounted(){
            if(!helper.hasPermission('list-beneficiaries')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getBeneficiary();
        },

        methods: {
            getBeneficiary(){
                
                axios.get('/api/beneficiaries/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        console.log(response);
                        this.beneficiary = response.beneficiary;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/beneficiaries');
                    });
            },

            approve(){
               axios.post('/api/beneficiaries/'+this.id+'/status')
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/beneficiaries');
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            }
        }
    }
</script>
